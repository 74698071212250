import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../utils/helper';

export const PublicRoute = ({ children }) => {
  const { isLoggedInSuccess } = useAuth();
  const { pathname } = useLocation();

  return isLoggedInSuccess ? (
    <Navigate to="/" replace state={{ path: pathname }} />
  ) : (
    children
  );
};
