import React, { useEffect } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { getCompany } from '../../redux/company';
import { EditIcon1, DeleteIcon } from '../../assets/icon';
import { HeadPart, TableContent } from './styled';
import { setBusPartner } from '../../redux/auth/slice';
import { useNavigate } from 'react-router-dom';

const CompaniesListComponent = () => {
  const { company } = useSelector((state) => state.company);
  const { userDetails } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (userDetails?.role == 'Admin') {
      dispatch(getCompany());
    }
  }, [userDetails]);
  const loginAsBusPartner = (busPartner) => {
    dispatch(setBusPartner(busPartner));
    localStorage.setItem('authToken', busPartner.token);
    navigate('/management');
  };
  return (
    <>
      <HeadPart>
        <Typography component="h2">{t('Company.Title')}</Typography>
      </HeadPart>
      <TableContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('Company.ID')}</TableCell>
                <TableCell>{t('Company.Name')}</TableCell>
                <TableCell>{t('Company.City')}</TableCell>
                <TableCell>{t('Company.Action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(company) ? (
                company.map((busPartner, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{busPartner.id}</TableCell>
                      <TableCell>{busPartner.name}</TableCell>
                      <TableCell>{busPartner.city}</TableCell>
                      <TableCell>
                        <EditIcon1
                          onClick={() => loginAsBusPartner(busPartner)}
                        />
                        <DeleteIcon />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    {t('Company.NoCompanyFound')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContent>
    </>
  );
};

export default CompaniesListComponent;
