import { createSlice } from '@reduxjs/toolkit';
import {
  getArrivalTime,
  getArrivalTimeReturn,
  postPriceCalculation,
  updateIncludeVat,
  updateManualAditional,
  updateRequestLanguage,
} from '../pricecalc';

const initialState = {
  loading: false,
  priceCalculationDetail: {},
  priceCalculationError: '',
  updateManualAditionalDetail: {},
  updateManualAditionalError: '',
  arrivalTimeDetail: {},
  arrivalTimeError: '',
  arrivalTimeDetailReturn: {},
  arrivalTimeErrorReturn: '',
  updateIncludeVatData: {},
  updateIncludeVatError: '',
  updateReqLanguageDetail: {},
  updateReqLanguageError: '',
};

const pricecalcSlice = createSlice({
  name: 'pricecalc',
  initialState,
  reducers: {
    reset: () => initialState,
    resetPriceCalculation: (state) => {
      state.priceCalculationDetail = {};
      state.priceCalculationError = '';
      state.updateIncludeVatData = {};
      state.updateIncludeVatError = '';
    },
    resetArrivalTime: (state) => {
      state.arrivalTimeDetail = {};
      state.arrivalTimeError = '';
    },
    resetArrivalTimeReturn: (state) => {
      state.arrivalTimeDetailReturn = {};
      state.arrivalTimeErrorReturn = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postPriceCalculation.pending, (state) => {
      state.loading = true;
      state.priceCalculationDetail = {};
      state.priceCalculationError = '';
    });
    builder.addCase(postPriceCalculation.fulfilled, (state, action) => {
      state.loading = false;
      state.priceCalculationDetail = action.payload?.response?.data?.data;
    });
    builder.addCase(postPriceCalculation.rejected, (state, err) => {
      state.loading = false;
      state.priceCalculationError = err?.payload;
    });

    builder.addCase(updateManualAditional.pending, (state) => {
      state.loading = true;
      state.updateManualAditionalDetail = {};
      state.updateManualAditionalError = '';
    });
    builder.addCase(updateManualAditional.fulfilled, (state, action) => {
      state.loading = false;
      state.updateManualAditionalDetail = action.payload?.response?.data?.data;
      state.priceCalculationDetail = action.payload?.response?.data?.data;
    });
    builder.addCase(updateManualAditional.rejected, (state, err) => {
      state.loading = false;
      state.updateManualAditionalError = err?.payload;
    });

    builder.addCase(getArrivalTime.pending, (state) => {
      state.loading = true;
      state.arrivalTimeDetail = {};
      state.arrivalTimeError = '';
    });
    builder.addCase(getArrivalTime.fulfilled, (state, action) => {
      state.loading = false;
      state.arrivalTimeDetail = action.payload?.response?.data?.data;
    });
    builder.addCase(getArrivalTime.rejected, (state, err) => {
      state.loading = false;
      state.arrivalTimeError = err?.payload;
    });

    builder.addCase(getArrivalTimeReturn.pending, (state) => {
      state.loading = true;
      state.arrivalTimeDetailReturn = {};
      state.arrivalTimeErrorReturn = '';
    });
    builder.addCase(getArrivalTimeReturn.fulfilled, (state, action) => {
      state.loading = false;
      state.arrivalTimeDetailReturn = action.payload?.response?.data?.data;
    });
    builder.addCase(getArrivalTimeReturn.rejected, (state, err) => {
      state.loading = false;
      state.arrivalTimeErrorReturn = err?.payload;
    });

    builder.addCase(updateIncludeVat.pending, (state) => {
      state.loading = true;
      state.updateIncludeVatData = {};
      state.updateIncludeVatError = '';
    });
    builder.addCase(updateIncludeVat.fulfilled, (state, action) => {
      state.loading = false;
      state.updateIncludeVatData = action.payload?.response?.data?.data;
      state.priceCalculationDetail = action.payload?.response?.data?.data;
    });
    builder.addCase(updateIncludeVat.rejected, (state, err) => {
      state.loading = false;
      state.updateIncludeVatError = err?.payload;
      state.priceCalculationError = err?.payload;
    });

    builder.addCase(updateRequestLanguage.pending, (state) => {
      state.loading = true;
      state.updateReqLanguageDetail = {};
      state.updateReqLanguageError = '';
    });
    builder.addCase(updateRequestLanguage.fulfilled, (state, action) => {
      state.loading = false;
      state.updateReqLanguageDetail = action.payload?.response?.data;
      state.priceCalculationDetail = action.payload?.response?.data?.data;
    });
    builder.addCase(updateRequestLanguage.rejected, (state, err) => {
      state.loading = false;
      state.updateReqLanguageError = err?.payload;
    });
  },
});

export const {
  reset,
  resetPriceCalculation,
  resetArrivalTime,
  resetArrivalTimeReturn,
} = pricecalcSlice.actions;

export default pricecalcSlice.reducer;
