import React, { useState } from 'react';
import { t } from 'i18next';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import {
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  DeleteIcon,
  BusIcon1,
  BusIcon2,
  BusIcon4,
  CloseIcon,
  SelectDropdownIcon,
} from '../../assets/icon';
import {
  HeadPart,
  TableContent,
  DeleteButton,
  ModalContent,
  ModalHeader,
  CloseButton,
  ModalContentData,
  ModalButtons,
  RedButton,
  GreyButton,
  GreenButton,
  AddBusField,
} from './styled';

const BusTypeComponent = ({ isClassActive }) => {
  const { values, setFieldValue, handleChange, handleBlur } =
    useFormikContext();
  const [selectedBus, setSelectedBus] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const deletebushandleOpen = (bus) => {
    setSelectedBus(bus);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteConfirm = () => {
    const updatedBusses = values?.buses.filter(
      (bus) => bus.id !== selectedBus.id,
    );
    setFieldValue('buses', updatedBusses);
    setIsDeleteModalOpen(false);
  };
  const handleAddBus = () => {
    const newBus = {
      id: 0,
      bus_type: values.bus_type || 'double_decker',
      max_number_passenger: values.max_number_passenger,
      quantity: values.quantity,
      daily_price: values.daily_price,
    };

    setFieldValue('buses', [...values.buses, newBus]);

    setFieldValue('bus_type', 'double_decker');
    setFieldValue('max_number_passenger', '');
    setFieldValue('quantity', '');
    setFieldValue('daily_price', '');

    setIsAddModalOpen(false);
  };
  return (
    <>
      <HeadPart>
        <Typography component="h2">{t('Bustype.FareBusType')}</Typography>
      </HeadPart>
      <TableContent>
        <TableContainer>
          <Table
            sx={{ width: '100%', maxWidth: '700px' }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '30px' }}></TableCell>
                <TableCell sx={{ width: '90px' }}></TableCell>
                <TableCell sx={{ width: '180px' }}>
                  {t('Bustype.MaximumPass')}
                </TableCell>
                <TableCell sx={{ width: '120px' }}>
                  {t('Bustype.NoBus')}
                </TableCell>
                <TableCell sx={{ width: '131px' }}>
                  {t('Bustype.DailyRate')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(values?.buses) &&
                values.buses.map((bus, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {isClassActive ? (
                          <DeleteButton
                            onClick={() => deletebushandleOpen(bus)}
                          >
                            <DeleteIcon />
                          </DeleteButton>
                        ) : (
                          ''
                        )}
                        {
                          <input
                            type="hidden"
                            name={`buses[${index}][id]`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={bus.id}
                          />
                        }
                      </TableCell>
                      <TableCell>
                        {
                          <input
                            type="hidden"
                            name={`buses[${index}][bus_type]`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={bus.bus_type}
                          />
                        }
                        {bus.bus_type === 'double_decker' ? (
                          <BusIcon4 />
                        ) : bus.bus_type === 'pullman_standard' ? (
                          <BusIcon2 />
                        ) : (
                          <BusIcon1 />
                        )}
                      </TableCell>
                      <TableCell>
                        {isClassActive ? (
                          <TextField
                            inputProps={{ min: 1 }}
                            type="number"
                            name={`buses[${index}][max_number_passenger]`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={(e) => {
                              if (
                                e.key === 'e' ||
                                e.key === 'E' ||
                                e.key === '-' ||
                                e.key === '+'
                              ) {
                                e.preventDefault();
                              }
                            }}
                            value={bus.max_number_passenger}
                            sx={{ width: '108px', margin: 'auto' }}
                          />
                        ) : (
                          <Typography>{bus.max_number_passenger}</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {isClassActive ? (
                          <TextField
                            inputProps={{ min: 1 }}
                            type="number"
                            name={`buses[${index}][quantity]`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={(e) => {
                              if (
                                e.key === 'e' ||
                                e.key === 'E' ||
                                e.key === '-' ||
                                e.key === '+'
                              ) {
                                e.preventDefault();
                              }
                            }}
                            value={bus.quantity}
                            sx={{ width: '108px', margin: 'auto' }}
                          />
                        ) : (
                          <Typography>{bus.quantity}</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {isClassActive ? (
                          <TextField
                            inputProps={{ min: 1 }}
                            type="number"
                            name={`buses[${index}][daily_price]`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={(e) => {
                              if (
                                e.key === 'e' ||
                                e.key === 'E' ||
                                e.key === '-' ||
                                e.key === '+'
                              ) {
                                e.preventDefault();
                              }
                            }}
                            value={bus.daily_price}
                            sx={{ width: '108px', margin: 'auto' }}
                          />
                        ) : (
                          <Typography>{`${bus.daily_price} €`}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {isClassActive ? (
          <Button onClick={() => setIsAddModalOpen(true)} variant="contained">
            {t('Bustype.AddBus')}
          </Button>
        ) : (
          ''
        )}
      </TableContent>
      {/* Start Delete Modal */}
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        aria-labelledby="delete-bus"
        aria-describedby="delete-bus-description"
      >
        <>
          <ModalContent>
            <ModalHeader>
              <Typography id="delete-bus" variant="h3">
                {t('Bustype.CanelConfirm')}
              </Typography>
              <CloseButton onClick={() => setIsDeleteModalOpen(false)}>
                <CloseIcon />
              </CloseButton>
            </ModalHeader>
            <ModalContentData>
              <Typography id="delete-bus-description" variant="h5">
                {t('Bustype.CancelConfirmText')}
              </Typography>
              <ModalButtons>
                <RedButton>
                  <Button onClick={handleDeleteConfirm} variant="contained">
                    {t('Bustype.CancelYes')}
                  </Button>
                </RedButton>
                <GreyButton>
                  <Button
                    onClick={() => setIsDeleteModalOpen(false)}
                    variant="contained"
                  >
                    {t('Bustype.CancelNo')}
                  </Button>
                </GreyButton>
              </ModalButtons>
            </ModalContentData>
          </ModalContent>
        </>
      </Modal>
      {/* End Delete Modal */}

      {/* Start Add Bus Modal */}
      <Modal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        aria-labelledby="add-bus"
        aria-describedby="add-bus-body"
      >
        <>
          <ModalContent>
            <ModalHeader>
              <Typography id="add-bus" variant="h3">
                {t('Bustype.AddBus')}
              </Typography>
              <CloseButton onClick={() => setIsAddModalOpen(false)}>
                <CloseIcon />
              </CloseButton>
            </ModalHeader>
            <ModalContentData id="add-bus-body">
              <AddBusField>
                <InputLabel>{t('Bustype.BusType')}</InputLabel>
                <Select
                  name="bus_type"
                  sx={{ width: '210px' }}
                  defaultValue={'double_decker'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  IconComponent={() => <SelectDropdownIcon />}
                >
                  <MenuItem value="double_decker">Double-decker</MenuItem>
                  <MenuItem value="pullman_standard">
                    Pullman standard/ GT
                  </MenuItem>
                  <MenuItem value="minibus">Minibus/ Minivan</MenuItem>
                </Select>
              </AddBusField>
              <AddBusField>
                <InputLabel>{t('Bustype.MaxPass')}</InputLabel>
                <TextField
                  inputProps={{ min: 1 }}
                  type="number"
                  name="max_number_passenger"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    if (
                      e.key === 'e' ||
                      e.key === 'E' ||
                      e.key === '-' ||
                      e.key === '+'
                    ) {
                      e.preventDefault();
                    }
                  }}
                  sx={{ width: '87px' }}
                />
              </AddBusField>
              <AddBusField>
                <InputLabel>{t('Bustype.Bus')}</InputLabel>
                <TextField
                  inputProps={{ min: 1 }}
                  type="number"
                  name="quantity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    if (
                      e.key === 'e' ||
                      e.key === 'E' ||
                      e.key === '-' ||
                      e.key === '+'
                    ) {
                      e.preventDefault();
                    }
                  }}
                  sx={{ width: '87px' }}
                />
              </AddBusField>
              <AddBusField>
                <InputLabel>{t('Bustype.DailyRate')}</InputLabel>
                <TextField
                  inputProps={{ min: 1 }}
                  type="number"
                  name="daily_price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    if (
                      e.key === 'e' ||
                      e.key === 'E' ||
                      e.key === '-' ||
                      e.key === '+'
                    ) {
                      e.preventDefault();
                    }
                  }}
                  sx={{ width: '87px' }}
                />
              </AddBusField>
              <ModalButtons>
                <GreenButton>
                  <Button onClick={handleAddBus} variant="contained">
                    {t('Bustype.Add')}
                  </Button>
                </GreenButton>
                <GreyButton>
                  <Button
                    onClick={() => setIsAddModalOpen(false)}
                    variant="contained"
                  >
                    {t('Bustype.Cancel')}
                  </Button>
                </GreyButton>
              </ModalButtons>
            </ModalContentData>
          </ModalContent>
        </>
      </Modal>
      {/* Add Bus Modal */}
    </>
  );
};

export default BusTypeComponent;
