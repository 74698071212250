import { createSlice } from '@reduxjs/toolkit';
import {
  customerNotify,
  getHistory,
  getHistoryDetail,
  getOfferDetail,
  sendOffer,
  sendOfferToSupplier,
  submitSupplierOffer,
  updateManualAditionalDetailPage,
  updateRequestLanguageDetailPage,
  updateRequestStatus,
  updateCustomerTripRequestEmail,
  getConversationForPartnerThunk,
} from '../reqhistory';

const initialState = {
  loading: false,
  requestHistoryDetail: {},
  requestHistoryError: '',
  requestHistoryInfo: {},
  requestHistoryInfoError: '',
  updateCustomerTripRequestEmailStatus: '',
  updateManualAditionalDetail: {},
  updateManualAditionalError: '',
  sendOfferDetail: {},
  sendOfferError: '',
  sendOfferStatus: '',
  updateReqLanguageDetail: {},
  updateReqLanguageError: '',
  customerNotifyDetail: {},
  customerNotifyError: '',
  offerDetail: {},
  offerError: '',
  sendOfferToSupplierDetail: {},
  sendOfferToSupplierError: '',
  submitOffer: {},
  submitOfferError: '',
};

const reqHistorySlice = createSlice({
  name: 'reqhistory',
  initialState,
  reducers: {
    reset: () => initialState,
    resetReqHistoryInfo: (state) => {
      state.requestHistoryInfo = {};
      state.requestHistoryInfoError = '';
    },
    updateRequestDetail: (state, action) => {
      state.requestHistoryInfo = action.payload;
    },
    resetSubmitOffer: (state) => {
      state.submitOffer = {};
      state.submitOfferError = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHistory.pending, (state) => {
      state.loading = true;
      state.requestHistoryDetail = {};
      state.requestHistoryError = '';
    });
    builder.addCase(getHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.requestHistoryDetail = action.payload?.response?.data?.data;
    });
    builder.addCase(getHistory.rejected, (state, err) => {
      state.loading = false;
      state.requestHistoryError = err?.payload;
    });

    builder.addCase(getHistoryDetail.pending, (state) => {
      state.loading = true;
      state.requestHistoryInfo = {};
      state.requestHistoryInfoError = '';
    });
    builder.addCase(getHistoryDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.requestHistoryInfo = action.payload?.response?.data?.data;
    });
    builder.addCase(getHistoryDetail.rejected, (state, err) => {
      state.loading = false;
      state.requestHistoryInfoError = err?.payload;
    });

    builder.addCase(getConversationForPartnerThunk.pending, (state) => {
      state.loading = true;
      state.conversations = {};
    });
    builder.addCase(
      getConversationForPartnerThunk.fulfilled,
      (state, action) => {
        console.log();
        state.loading = false;
        if (state.conversations == null) {
          state.conversations = {};
        }
        console.log(action.payload);
        state.conversations[action.payload.tripRequestCode] =
          action.payload?.conversations;
      },
    );
    builder.addCase(getConversationForPartnerThunk.rejected, (state, err) => {
      state.loading = false;
      state.conversations.error = 'ERROR';
    });

    builder.addCase(updateRequestStatus.pending, (state) => {
      state.loading = true;
      state.updateReqStatusDetail = {};
      state.updateReqStatusError = '';
    });
    builder.addCase(updateRequestStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.updateReqStatusDetail = {
        ...action.payload?.response?.data,
        updatedStatus: action.meta.arg.status,
      };
      state.requestHistoryInfo.status = action.meta.arg.status;
    });
    builder.addCase(updateRequestStatus.rejected, (state, err) => {
      state.loading = false;
      state.updateReqStatusError = err?.payload;
    });

    builder.addCase(updateRequestLanguageDetailPage.pending, (state) => {
      state.loading = true;
      state.updateReqLanguageDetail = {};
      state.updateReqLanguageError = '';
    });
    builder.addCase(
      updateRequestLanguageDetailPage.fulfilled,
      (state, action) => {
        state.loading = false;
        state.updateReqLanguageDetail = action.payload?.response?.data;
      },
    );
    builder.addCase(updateRequestLanguageDetailPage.rejected, (state, err) => {
      state.loading = false;
      state.updateReqLanguageError = err?.payload;
    });

    builder.addCase(sendOffer.pending, (state) => {
      state.loading = true;
      state.sendOfferDetail = {};
      state.sendOfferError = '';
      state.sendOfferStatus = 'PENDING';
    });
    builder.addCase(sendOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.sendOfferDetail = action.payload?.response?.data;
      state.sendOfferStatus = 'SUCCESS';
    });
    builder.addCase(sendOffer.rejected, (state, err) => {
      state.loading = false;
      state.sendOfferStatus = 'ERROR';
      state.sendOfferError = err?.payload;
    });

    builder.addCase(updateManualAditionalDetailPage.pending, (state) => {
      state.loading = true;
      state.updateManualAditionalDetail = {};
      state.updateManualAditionalError = '';
    });
    builder.addCase(
      updateManualAditionalDetailPage.fulfilled,
      (state, action) => {
        state.loading = false;
        state.updateManualAditionalDetail =
          action.payload?.response?.data?.data;
        state.requestHistoryInfo = action.payload?.response?.data?.data;
      },
    );
    builder.addCase(updateManualAditionalDetailPage.rejected, (state, err) => {
      state.loading = false;
      state.updateManualAditionalError = err?.payload;
    });

    builder.addCase(customerNotify.pending, (state) => {
      state.loading = true;
      state.customerNotifyDetail = {};
      state.customerNotifyError = '';
    });
    builder.addCase(customerNotify.fulfilled, (state, action) => {
      state.loading = false;
      state.customerNotifyDetail = action.payload?.response?.data;
    });
    builder.addCase(customerNotify.rejected, (state, err) => {
      state.loading = false;
      state.customerNotifyError = err?.payload;
    });

    builder.addCase(getOfferDetail.pending, (state) => {
      state.loading = true;
      state.offerDetail = {};
      state.offerDetailError = '';
    });
    builder.addCase(getOfferDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.offerDetail = action.payload?.response?.data?.data;
    });
    builder.addCase(getOfferDetail.rejected, (state, err) => {
      state.loading = false;
      state.offerDetailError = err?.payload;
    });

    builder.addCase(sendOfferToSupplier.pending, (state) => {
      state.loading = true;
      state.sendOfferToSupplierDetail = {};
      state.sendOfferToSupplierError = '';
    });
    builder.addCase(sendOfferToSupplier.fulfilled, (state, action) => {
      state.loading = false;
      state.sendOfferToSupplierDetail = action.payload?.response?.data;
    });
    builder.addCase(sendOfferToSupplier.rejected, (state, err) => {
      state.loading = false;
      state.sendOfferToSupplierError = err?.payload;
    });

    builder.addCase(submitSupplierOffer.pending, (state) => {
      state.loading = true;
      state.submitOffer = {};
      state.submitOfferError = '';
    });
    builder.addCase(submitSupplierOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.submitOffer = action.payload?.response?.data;
    });
    builder.addCase(submitSupplierOffer.rejected, (state, err) => {
      state.loading = false;
      state.submitOfferError = err?.payload;
    });

    builder.addCase(updateCustomerTripRequestEmail.rejected, (state) => {
      state.loading = false;
      state.updateCustomerTripRequestEmailStatus = 'ERROR';
    });

    builder.addCase(updateCustomerTripRequestEmail.pending, (state) => {
      state.loading = true;
      state.updateCustomerTripRequestEmailStatus = 'PENDING';
      if (state.requestHistoryInfo == null) {
        state.requestHistoryInfo = {};
      }
      if (state.requestHistoryInfo.additional_data == null) {
        state.requestHistoryInfo.additional_data = {};
      }
    });
    builder.addCase(
      updateCustomerTripRequestEmail.fulfilled,
      (state, action) => {
        state.loading = false;
        state.updateCustomerTripRequestEmailStatus = 'SUCCESS';
        console.log(action);
        state.requestHistoryInfo.additional_data.custom_trip_request_email =
          action.payload.custom_html_email;
      },
    );
  },
});

export const {
  reset,
  resetReqHistoryInfo,
  resetSubmitOffer,
  updateRequestDetail,
} = reqHistorySlice.actions;

export default reqHistorySlice.reducer;
