import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 0 21px 0;
  max-width: 574px;
  button.MuiButton-contained {
    border-radius: 5px;
    background: #5c5be6;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 12px 16px;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
    text-transform: none;
    &:before {
      content: '+';
      font-size: 50px;
      line-height: 30px;
      position: relative;
      top: 3px;
      margin-right: 15px;
    }
  }
  h2 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: ${theme.colors.black};
    line-height: normal;
  }
`;

export const TableContent = styled.div`
  border-radius: 21px;
  border: 1px solid ${theme.colors.grey};
  margin-bottom: 52px;
  max-width: 574px;
  table {
    thead {
      th {
        color: ${theme.colors.black};
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.4px;
        text-align: center;
        border: none;
        padding: 15px 20px;
        border-bottom: 1px solid ${theme.colors.grey1};
      }
    }
    tbody {
      tr {
        &:first-of-type td {
          padding-top: 15px;
        }
        &:last-child td {
          padding-bottom: 15px;
        }
        td {
          border: none;
          padding: 15px 20px;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.4px;
          color: ${theme.colors.black} input {
            padding: 10.5px 10px 7.5px;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.3px;
            color: ${theme.colors.black};
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          fieldset {
            border: 1px solid ${theme.colors.grey};
            border-radius: 6px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          svg {
            width: 22px;
            height: 22px;
            margin: 0 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
  button.MuiButton-contained {
    border-radius: 5px;
    background: ${theme.colors.primary};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 12px 16px;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
    text-transform: none;
    margin-left: 60px;
    margin-top: 33px;
    &:before {
      content: '+';
      font-size: 50px;
      line-height: 30px;
      position: relative;
      top: 3px;
      margin-right: 15px;
    }
  }
`;

export const DeleteButton = styled.div`
  width: 24px;
  height: 24px;
  background: ${theme.colors.grey1};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
`;
