import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const Heading = styled.h3`
  font-family: League Spartan;
  font-size: 40px;
  font-weight: 700;
  line-height: 32.45px;
  letter-spacing: -0.02em;
  text-align: center;
`;

export const ItineraryTitle = styled.p`
  font-family: League Spartan;
  font-size: 20px;
  font-weight: 400;
  line-height: 32.45px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 0 0 20px;
  span {
    font-family: League Spartan;
    font-size: 23px;
    font-weight: 700;
    line-height: 32.45px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #5c5be6;
  }
`;

export const StatisticsBlock = styled.div`
  text-align: center;
  margin-left: 0 !important;
  h5 {
    color: ${theme.colors.primary};
    font-weight: bold;
    font-size: 20px;
    @media (max-width: 1240px) {
      font-size: 18px;
    }
  }
  span {
    font-size: 18px;
    line-height: 22px;
  }
`;
export const FlashContent = styled.div`
  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: ${theme.colors.black};
    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
    .green {
      color: rgba(8, 170, 43, 0.96);
    }
  }
  table {
    @media screen and (max-width: 991px) {
      white-space: nowrap;
    }
    thead {
      tr {
        th {
          font-size: 16px;
          font-weight: 700;
          background: rgba(0, 0, 0, 0.06);
          border: 1px solid ${theme.colors.grey};
          padding: 10px 12px;
          line-height: normal;
        }
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid ${theme.colors.grey};
          background: ${theme.colors.white};
          padding: 10px 12px;
          color: ${theme.colors.black};
          font-size: 16px;
          font-weight: 700;
          line-height: normal;
          span {
            font-weight: 400;
          }
          input {
            padding: 10.5px 10px 7.5px;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.3px;
            color: ${theme.colors.black};
          }
        }
        td.grey {
          background: rgba(0, 0, 0, 0.06);
        }
      }
    }
    tfoot {
      tr {
        td {
          border: 1px solid ${theme.colors.grey};
          border-bottom: none;
          background: ${theme.colors.black};
          padding: 10px 12px;
          color: ${theme.colors.white};
          font-size: 16px;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
    strong {
      font-weight: 700;
    }
  }
  ul {
    padding: 0;
    list-style-type: disc;
    li {
      display: block;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: ${theme.colors.black};
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
      }
      &:before {
        content: '';
        width: 5px;
        height: 5px;
        background: ${theme.colors.black};
        border-radius: 100px;
        position: absolute;
        left: 0;
        margin-top: 4px;
      }
      strong {
        color: ${theme.colors.primary};
      }
    }
  }
  span {
    &.MuiTypography-button {
      border-radius: 3px;
      background: ${theme.colors.primary};
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      color: ${theme.colors.white};
      text-transform: none;
      padding: 8px;
      margin-top: 50px;
      display: inline-block;
      line-height: normal;
      @media screen and (max-width: 991px) {
        margin-top: 20px;
      }
    }
  }
  .stepOneHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
  .stepOneButton {
    @media (max-width: 991px) {
      margin-left: auto;
      padding: 0;
      margin-bottom: 10px;
    }
  }
`;
export const SubmitButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  @media screen and (max-width: 767px) {
    justify-content: flex-end;
    margin-top: 30px;
  }
  .MuiCircularProgress-indeterminate {
    margin-right: 10px;
  }
  button.MuiButton-contained {
    border-radius: 100px;
    align-item: right;
    background: ${theme.colors.lightblack};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    text-transform: none;
    letter-spacing: -0.472px;
    padding: 13px 15px;
    line-height: normal;
    svg {
      fill: ${theme.colors.white};
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }
  }
`;
export const TermsConditions = styled.div`
  max-width: 380px;
  margin-left: auto;
  margin-top: 20px;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
  a.MuiLink-root {
    color: #195eb0;
    font-weight: 700;
  }
`;
export const PartnerFormWrapp = styled.div`
  .formWrapp {
    display: flex;
    gap: 40px;
    -webkit-box-flex-wrap: nowrap;
    flex-wrap: nowrap;
    @media (max-width: 768px) {
      -webkit-box-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .MuiInputBase-input {
      padding-top: 12.5px;
      padding-bottom: 12.5px;
    }
    fieldset {
      border: 1px solid ${theme.colors.borderColor};
      border-radius: 6px;
    }
    &:not(:last-child) {
      margin-bottom: 34px;
    }
  }
`;
export const FormBox = styled.div`
  .boxHeading {
    font-size: 25px;
    font-weight: 700;
    line-height: 32.45px;
    letter-spacing: -0.02em;
    color: ${theme.colors.black};
  }
`;
export const SubmitWrapper = styled.div`
  margin-top: 20px;
  // max-width: 847px;
  margin-left: auto;
  // margin-right: auto;
`;

export const DetailsBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  border: 1px solid #bbbbbb;
  border-radius: 6px;
  padding: 24px;
  box-sizing: inherit;
  h4 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
    span {
      color: ${theme.colors.primary};
    }
  }
  fieldset {
    border: 1px solid ${theme.colors.grey}!important;
    border-radius: 8px !important;
  }
  .customDivider {
    margin-left: 0;
  }
`;

export const FormInputGroup = styled.div`
  @media (max-width: 768px) {
    width: 100% !important;
  }
  .MuiFormControl-root {
    width: 100%;
  }
`;
