import styled from '@emotion/styled';
import theme from '../../../themes/base.json';

export const ClientContent = styled.div`
  border-radius: 8px;
  border: 1px solid ${theme.colors.grey};
  padding: 16px;
`;

export const EditButton = styled.div`
  text-align: right;
  position: sticky;
  top: 0;
  background: ${theme.colors.white};
  padding: 10px 0;
  z-index: 9;
  @media screen and (max-width: 991px) {
    padding: 10px 15px;
  }
  button {
    border-radius: 26.5px;
    background: ${theme.colors.black};
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    padding: 12px 22px;
    width: 140px;
    svg {
      margin-right: 5px;
      width: 22px;
      height: 22px;
    }
    &.save-btn {
      background: ${theme.colors.green};
      svg {
        margin-right: 12px;
      }
      &.Mui-disabled {
        cursor: not-allowed;
        opacity: 0.6;
        path {
          fill: ${theme.colors.black};
          opacity: 0.3;
        }
      }
    }
    &.cancel {
      background: ${theme.colors.grey3};
      margin: 0 0 0 10px;
      path {
        fill: ${theme.colors.white};
      }
      &.Mui-disabled {
        cursor: not-allowed;
        opacity: 0.6;
        path {
          fill: ${theme.colors.lightblack};
          opacity: 0.3;
        }
      }
    }
    &.reset-btn {
      padding: 15px 22px;
      margin-left: 4px;
      background: ${theme.colors.green};
      &.Mui-disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
`;
