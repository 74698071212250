import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  button.MuiButton-contained {
    border-radius: 6px;
    gap: 10px;
    background: ${theme.colors.darkGrey};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: none;
    letter-spacing: -0.472px;
    padding: 13px 15px;
    line-height: normal;
    svg {
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }
  }
  h2 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: ${theme.colors.black};
    line-height: normal;
  }
`;

export const CheckBoxSelect = styled.div`
  // width: calc(100% - 112px);
  padding-right: 15px;
  .MuiFormControlLabel-root {
    margin: 0;
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      color: ${theme.colors.black};
      letter-spacing: -0.472px;
      margin-left: 10px;
      position: relative;
      top: 2px;
      width: calc(100% - 35px);
    }
  }
  input[type='radio'] {
    margin: 0;
    appearance: none;
    width: 25px;
    height: 25px;
    border: 1px solid ${theme.colors.primary};
    border-radius: 100px;
    position: relative;
    padding: 0;
    &:checked {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 15px;
        height: 15px;
        border-radius: 100px;
        background: ${theme.colors.primary};
        margin: auto;
      }
    }
  }
  input[type='checkbox'] {
    margin: 0;
    appearance: none;
    width: 25px;
    height: 25px;
    border: 1px solid ${theme.colors.primary};
    border-radius: 100px;
    position: relative;
    padding: 0;
    &:checked {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 15px;
        height: 15px;
        border-radius: 100px;
        background: ${theme.colors.primary};
        margin: auto;
      }
    }
  }
`;

export const TripInfo = styled.div``;
export const MainTripBox = styled.div`
  &:not(:first-of-type) {
    padding-left: 100px;
    @media screen and (max-width: 1400px) {
      padding-left: 50px;
    }
    @media screen and (max-width: 767px) {
      padding-left: 25px;
    }
  }
  &:first-of-type {
    .button {
      padding-left: 100px;
      @media screen and (max-width: 1400px) {
        padding-left: 50px;
        @media screen and (max-width: 767px) {
          padding-left: 25px;
        }
      }
    }
  }
`;

export const AddStopButton = styled.div`
  text-align: center;
  button.MuiButton-contained {
    border-radius: 26.5px;
    background: ${theme.colors.green};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    padding: 8px 15px;
    text-transform: none;
    &:before {
      content: '+';
      font-size: 30px;
      line-height: 30px;
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }
`;

export const BottomInfo = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const BottomContentLeft = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
  .MuiFormGroup-root {
    margin-top: 32px;
  }
  input {
    text-align: right;
    padding: 12px 22.5px 12px 12.5px;
  }
  fieldset {
    border-radius: 6px;
    border: 1px solid ${theme.colors.grey};
  }
`;
export const BottomContentRight = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    justify-content: flex-end;
    margin-top: 30px;
  }
  .MuiCircularProgress-indeterminate {
    margin-right: 10px;
  }
  button.MuiButton-contained {
    border-radius: 6px;
    background: ${theme.colors.primary};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: none;
    letter-spacing: -0.472px;
    padding: 13px 15px;
    line-height: normal;
    svg {
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }
  }
`;

export const BottomInput = styled.div`
  width: 112px;
  margin: 0 0 0 auto;
  label {
    font-size: 15px;
    font-weight: 400;
    color: ${theme.colors.black};
    line-height: 32.447px;
    letter-spacing: -0.3px;
  }
`;
export const CustomDivider = styled.hr`
  border: none;
  height: 1px;
  background-color: #bbbbbb;
  width: 80%;
  margin: 20px auto;
`;
