import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
  margin-top: 7px;
  margin-bottom: 12px;
  h2 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: ${theme.colors.black};
    line-height: normal;
  }
`;
export const ProfileDetalBox = styled.div`
  border-radius: 21px;
  border: 1px solid ${theme.colors.grey};
  padding: 30px;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    padding: 30px 15px;
  }
  .MuiGrid-item {
    margin: 0 0 0 0;
    padding-left: 26px;
  }
  h6 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
    color: ${theme.colors.black};
    margin: 0 0 15px 0;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.36px;
    color: ${theme.colors.black};
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  a {
    color: ${theme.colors.black};
    text-decoration: none;
    transition: 0.3s all;
    &:hover {
      color: ${theme.colors.primary};
    }
  }
  a.busLink {
    color: ${theme.colors.primary};
    transition: 0.3s all;
    text-decoration: underline;
    font-weight: 700;
  }
`;
