import styled from '@emotion/styled';

export const MainPricing = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px 0;
  flex-wrap: wrap;
`;
export const PrcingBox = styled.div`
  border-radius: 21px;
  border: 1px solid grey;
  background: #fff;
  width: 100%;
  max-width: 362px;
  text-align: center;
  padding: 27px 11px;
  @media screen and (max-width: 1600px) {
    width: calc(33.33% - 38px);
    max-width: 100%;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
  &:nth-of-type(2) {
    margin: 0 20px;
    @media screen and (max-width: 575px) {
      margin: 15px 0;
    }
  }
  span {
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.2px;
    color: #5c5be6;
    margin: 0 0 15px 0;
    display: block;
    @media screen and (max-width: 1199px) {
      font-size: 40px;
      margin: 0 0 10px 0;
    }
    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
  }
  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
    color: #000;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
`;
