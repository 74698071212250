/* eslint-disable no-undef */
import Axios from 'axios';

export function unAuthenticatedInstance() {
  return Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
}

export function authenticatedInstance(isAdmin, params) {
  const headers = {
    Authorization: `Bearer ${isAdmin ? localStorage.getItem('authTokenAdmin') : localStorage.getItem('authToken') || localStorage.getItem('authTokenAdmin')}`,
  };

  const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: headers,
    params,
  });
  axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      if (response.data && response.data.status_code === 401) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('authTokenAdmin');
        window.location.href = '/login';
      }
      return response;
    },
    function (error) {
      if (error.response && error.response.status === 401) {
        if (error.config.headers['Authorization']) {
          localStorage.removeItem('authToken');
          localStorage.removeItem('authTokenAdmin');
          window.location.href = '/login';
        }
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    },
  );
  return axios;
}

const newApiAxios = Axios.create({
  baseURL: process.env.REACT_APP_NEW_API_URL,
});

const getAuthHeaders = (isAdmin = false) => {
  return {
    Authorization: `Bearer ${isAdmin ? localStorage.getItem('authTokenAdmin') : localStorage.getItem('authToken') || localStorage.getItem('authTokenAdmin')}`,
  };
};

export function authGet({ route, isAdmin }) {
  console.log(process.env.REACT_APP_NEW_API_URL);
  return newApiAxios.get(route, { headers: { ...getAuthHeaders(isAdmin) } });
}

export function authPost({ route, body, isAdmin }) {
  return newApiAxios.post(route, body, {
    headers: { ...getAuthHeaders(isAdmin) },
  });
}

export function callPostAPI({
  route,
  body,
  isAuthenticated = true,
  isAdmin = false,
}) {
  if (isAuthenticated) {
    const requestInstance = authenticatedInstance(isAdmin);
    return requestInstance.post(route, body);
  }
  const requestInstance = unAuthenticatedInstance();
  return requestInstance.post(route, body);
}

export function callPutAPI({
  route,
  body,
  isAuthenticated = true,
  isAdmin = false,
}) {
  if (isAuthenticated) {
    const requestInstance = authenticatedInstance(isAdmin);
    return requestInstance.put(route, body);
  }
  const requestInstance = unAuthenticatedInstance();
  return requestInstance.put(route, body);
}

export function callGetAPI({
  route,
  isAuthenticated = true,
  isAdmin = false,
  params = null,
}) {
  if (isAuthenticated) {
    const requestInstance = authenticatedInstance(isAdmin, params);
    return requestInstance.get(route);
  }
  const requestInstance = unAuthenticatedInstance();
  return requestInstance.get(route);
}

// SignUp
export function registerInstance() {
  return axios.create({
    baseURL: 'https://reqres.in/api/register',
  });
}

export function userSignUp({ route, body }) {
  const requestSignUp = registerInstance();
  return requestSignUp.post(route, body);
}
