import React from 'react';
import { t } from 'i18next';
import { Typography } from '@mui/material';
import { CheckmarkIcon } from '../../assets/icon/index.js';
import { SuccessNotification, CheckMark } from './styled.js';

const SuccessNotificationComponent = () => {
  return (
    <>
      <SuccessNotification>
        <CheckMark>
          <CheckmarkIcon />
        </CheckMark>
        <Typography variant="span">{t('Management.SuccessMsg')}</Typography>
      </SuccessNotification>
    </>
  );
};

export default SuccessNotificationComponent;
