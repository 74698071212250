import React from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // Import the duration plugin
dayjs.extend(duration); // Extend dayjs with the duration plugin
// import isEmpty from 'lodash/isEmpty';
export const AuthContext = React.createContext(null);
export const useAuth = () => React.useContext(AuthContext);
export const extractDetails = (addressComponents) => {
  let postCode = '',
    city = '',
    region = '',
    region2 = '',
    country = '';
  addressComponents.forEach((component) => {
    const componentType = component.types[0];

    switch (componentType) {
      case 'postal_code': {
        postCode = component.long_name;
        break;
      }
      case 'administrative_area_level_1': {
        region = component.long_name;
        break;
      }
      case 'administrative_area_level_2': {
        region2 = component.long_name;
        break;
      }
      case 'administrative_area_level_3':
      case 'locality': {
        city = component.long_name;
        break;
      }
      case 'country': {
        country = component.long_name;
        break;
      }
    }
  });

  return {
    post_code: postCode,
    city: city || region2 || country,
    region,
    country,
  };
};
export const convertTripData = (tripStops) => {
  let newFormat = [];
  for (let i = 0; i < tripStops.length; i++) {
    let stop = tripStops[i];
    let newStopStart = {
      sequence: i + 1,
      departure_date:
        dayjs(stop.date).format('YYYY-MM-DD') +
        ' ' +
        dayjs(stop.time).format('HH:mm:ss'),
      lat: stop.start.lat,
      lon: stop.start.lon,
      address: stop.start.address,
      post_code: stop.start.post_code || null,
      city: stop.start.city,
      region: stop.start.region,
      country: stop.start.country,
    };
    newFormat.push(newStopStart);
  }
  let lastStop = tripStops[tripStops.length - 1];
  let newStopEnd = {
    sequence: tripStops.length + 1,
    departure_date: null,
    lat: lastStop.end.lat,
    lon: lastStop.end.lon,
    address: lastStop.end.address,
    post_code: lastStop.end.post_code || null,
    city: lastStop.end.city,
    region: lastStop.end.region,
    country: lastStop.end.country,
  };
  newFormat.push(newStopEnd);
  return newFormat;
};
export const getStopSequence = (segments, seprator = ' - ') => {
  const cities = segments.map((segment) => segment.departure_stop.city);
  cities.push(segments[cities.length - 1].destination_stop.city);
  return cities.join(seprator);
};
export const formatItalianNumber = (number) => {
  // return number;
  /* if (isNaN(number)) {
		console.error('Invalid input. Please provide a valid number.');
		return null;
	} */
  // Convert the number to an integer and format it with commas
  const formattedNumber = Math.floor(number).toLocaleString('it-IT');
  return formattedNumber;
};
export const getEuropeCountries = () => {
  return ['AT', 'CH', 'DE', 'FR', 'IT'];
};
export const convertTripDetails = (tripStop) => {
  let newStopStart = {};
  if (
    tripStop.start.lat &&
    tripStop.start.lon &&
    tripStop.end.lat &&
    tripStop.end.lon
  ) {
    newStopStart = {
      departure_lat: tripStop.start.lat,
      departure_lng: tripStop.start.lon,
      destination_lat: tripStop.end.lat,
      destination_lng: tripStop.end.lon,
    };
  }
  return newStopStart;
};
export const convertSecondsToHoursAndMinutesWithDayjs = (seconds) => {
  const duration = dayjs.duration(seconds, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  return { hours, minutes };
};
export const addDurationToTime = (inputDateTime, durationInSeconds) => {
  // Parse the input date and time
  const parsedDateTime = dayjs(inputDateTime, { format: 'YYYY-MM-DD HH:mm' });

  // Add the specified duration in seconds
  const newDateTime = parsedDateTime.add(durationInSeconds, 'seconds');
  return newDateTime.format('YYYY-MM-DD HH:mm');
};
export const roundToNearest15MinutesAfterAddingDuration = (
  inputDateTime,
  durationInSeconds,
) => {
  // Parse the input date and time
  const parsedDateTime = dayjs(inputDateTime, { format: 'YYYY-MM-DD HH:mm' });

  // Add the specified duration in seconds
  const newDateTime = parsedDateTime.add(durationInSeconds, 'seconds');

  // Calculate the difference in minutes from the next 15-minute slot
  const diffMinutes = 15 - (newDateTime.minute() % 15);

  // Add the difference to round to the next 15-minute slot
  const roundedDateTime =
    diffMinutes < 15 ? newDateTime.add(diffMinutes, 'minutes') : newDateTime;

  const finalDateTime = roundedDateTime.add(15, 'minutes');

  return finalDateTime.format('YYYY-MM-DD HH:mm');
};
export const getBrowserLanguage = () => {
  let lang = navigator.language || navigator.userLanguage; // Get browser language
  lang = lang.substr(0, 2); // Consider only first two characters
  if (lang !== 'it') {
    lang = 'en'; // If not 'it', consider 'en'
  }
  return lang;
};
export const getClientNotifyStatus = () => {
  return ['declined', 'offered'];
};
export const isValidEmail = (email) => {
  // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const re1 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re1.test(String(email).toLowerCase());
};
