import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/auth';

export const getDepots = createAsyncThunk(
  'getDepots',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callGetAPI({ route: 'depots/get', params });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addDepot = createAsyncThunk(
  'addDepot',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({ route: 'depot/add', body: params });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editDepot = createAsyncThunk(
  'editDepot',
  async (params, { rejectWithValue }) => {
    try {
      const { id, ...body } = params;
      const response = await callPostAPI({ route: 'depot/edit/' + id, body });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteDepot = createAsyncThunk(
  'deleteDepot',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({ route: 'depot/delete/' + params });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
