import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import PriceQuotaComponent from '../PriceQuotaComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { getHistoryDetail } from '../../redux/reqhistory';
import {
  reset,
  resetReqHistoryInfo,
  updateRequestDetail,
} from '../../redux/reqhistory/slice';
import { resetPriceCalculation } from '../../redux/pricecalc/slice';
import { DetailPage } from './styled';

const EstimateDetailComponent = () => {
  const [currentTab, setCurrentTab] = useState('agent');
  const {
    requestHistoryInfo,
    requestHistoryInfoError,
    updateReqLanguageDetail,
  } = useSelector((state) => state.reqhistory);
  const { updateIncludeVatData } = useSelector((state) => state.pricecalc);
  const { userDetails } = useSelector((state) => state.auth);
  const { requestId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isEmpty(requestId)) {
      const payload = {
        requestId,
        is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0,
      };
      document.title = `${requestId} | ${t('General.Quotabus')}`;
      dispatch(getHistoryDetail(payload));
    }
    return () => {
      document.title = t('General.Quotabus');
      reset();
    };
  }, [requestId]);
  useEffect(() => {
    return () => {
      dispatch(resetPriceCalculation());
      dispatch(resetReqHistoryInfo());
    };
  }, []);
  useEffect(() => {
    if (!isEmpty(updateIncludeVatData)) {
      dispatch(updateRequestDetail(updateIncludeVatData));
    }
  }, [updateIncludeVatData]);
  useEffect(() => {
    if (!isEmpty(updateReqLanguageDetail)) {
      const payload = {
        requestId,
        is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0,
      };
      dispatch(getHistoryDetail(payload));
    }
  }, [updateReqLanguageDetail]);

  useEffect(() => {
    if (
      requestHistoryInfoError?.status_code &&
      requestHistoryInfoError?.status_code >= 400
    ) {
      navigate('/request');
    }
  }, [requestHistoryInfoError]);

  return (
    <>
      {!isEmpty(requestHistoryInfo) && (
        <DetailPage>
          <PriceQuotaComponent
            priceCalculationDetail={requestHistoryInfo}
            detailPage={true}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </DetailPage>
      )}
    </>
  );
};

export default EstimateDetailComponent;
