import { createSlice } from '@reduxjs/toolkit';
import { addDepot, deleteDepot, editDepot, getDepots } from '../depot';

const initialState = {
  loading: false,
  depots: [],
  depotsError: '',
  addDepotDetails: {},
  addDepotError: '',
  editDepotDetails: {},
  editDepotError: '',
  deleteDepotDetails: {},
  deleteDepotError: '',
};

const depotSlice = createSlice({
  name: 'depot',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getDepots.pending, (state) => {
      state.loading = true;
      state.depots = [];
      state.depotsError = '';
    });
    builder.addCase(getDepots.fulfilled, (state, action) => {
      state.loading = false;
      state.depots = action.payload?.response.data.data;
    });
    builder.addCase(getDepots.rejected, (state, err) => {
      state.loading = false;
      state.depotsError = err?.payload?.message;
    });

    builder.addCase(addDepot.pending, (state) => {
      state.loading = true;
      state.addDepotDetails = {};
      state.addDepotError = '';
    });
    builder.addCase(addDepot.fulfilled, (state, action) => {
      state.loading = false;
      state.addDepotDetails = action.payload?.response.data.data;
    });
    builder.addCase(addDepot.rejected, (state, err) => {
      state.loading = false;
      state.addDepotError = err?.payload?.message;
    });

    builder.addCase(editDepot.pending, (state) => {
      state.loading = true;
      state.editDepotDetails = {};
      state.editDepotError = '';
    });
    builder.addCase(editDepot.fulfilled, (state, action) => {
      state.loading = false;
      state.editDepotDetails = action.payload?.response.data.data;
    });
    builder.addCase(editDepot.rejected, (state, err) => {
      state.loading = false;
      state.editDepotError = err?.payload?.message;
    });

    builder.addCase(deleteDepot.pending, (state) => {
      state.loading = true;
      state.deleteDepotDetails = {};
      state.deleteDepotError = '';
    });
    builder.addCase(deleteDepot.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteDepotDetails = action.payload?.response.data.data;
    });
    builder.addCase(deleteDepot.rejected, (state, err) => {
      state.loading = false;
      state.deleteDepotError = err?.payload?.message;
    });
  },
});

export const { reset } = depotSlice.actions;

export default depotSlice.reducer;
