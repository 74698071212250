import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
  @media screen and (max-width: 767px) {
    display: block;
  }
  h2 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: ${theme.colors.black};
    line-height: normal;
    margin: 0;
  }
  button.MuiButton-contained {
    border-radius: 5px;
    background: ${theme.colors.primary};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
    text-transform: none;
    margin-left: 60px;
    margin-top: 33px;
    @media screen and (max-width: 767px) {
      margin: 10px 0 0 0;
    }
    &:before {
      content: '+';
      font-size: 30px;
      line-height: 30px;
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }
`;

export const AddButton = styled.div`
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
  button.MuiButton-contained {
    border-radius: 5px;
    background: ${theme.colors.primary};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
    text-transform: none;
    margin: 0;
    &:before {
      content: '+';
      font-size: 30px;
      line-height: 30px;
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }
`;

export const TableContent = styled.div`
  border-radius: 21px;
  border: 1px solid ${theme.colors.grey};
  margin-bottom: 52px;
  table {
    @media screen and (max-width: 991px) {
      white-space: nowrap;
    }
    thead {
      th {
        color: ${theme.colors.black};
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.4px;
        // text-align: center;
        border: none;
        padding: 15px 20px 15px;
        border-bottom: 1px solid ${theme.colors.grey1};
      }
    }
    tbody {
      tr {
        &:first-of-type td {
          padding-top: 17px;
        }
        &:last-child td {
          padding-bottom: 17px;
        }
        td {
          border: none;
          padding: 15px;
          // text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.4px;
          color: ${theme.colors.black};
          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
          input {
            padding: 10.5px 10px 7.5px;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.3px;
            color: ${theme.colors.black};
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          fieldset {
            border: 1px solid ${theme.colors.grey};
            border-radius: 6px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
  button.MuiButton-contained {
    border-radius: 5px;
    background: ${theme.colors.primary};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 12px 16px;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
    text-transform: none;
    margin-left: 60px;
    margin-top: 33px;
    &:before {
      content: '+';
      font-size: 50px;
      line-height: 30px;
      position: relative;
      top: 3px;
      margin-right: 15px;
    }
  }
`;

export const DeleteButton = styled.div`
  width: 24px;
  height: 24px;
  background: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid ${theme.colors.grey};
  cursor: pointer;
  margin: 0 5px;
  &:first-of-type {
    svg {
      width: 16px;
      height: 16px;
      g {
        rect {
          fill: ${theme.colors.grey3};
        }
      }
    }
  }
`;

// Modal CSS

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 528px;
  border-radius: 10px;
  background: ${theme.colors.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  outline: none;
`;
export const ModalHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 8px 22px 7px 22px;
  border-bottom: 1px solid ${theme.colors.grey};
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    color: ${theme.colors.black};
  }
`;
export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid ${theme.colors.grey};
  border-radius: 100px;
  background: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;
export const ModalContentData = styled.div`
  padding: 34px 22px;
  text-align: center;
  h5 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    color: ${theme.colors.black};
    letter-spacing: -0.4px;
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 44px;
  button.MuiButton-contained {
    border-radius: 6px;
    background: ${theme.colors.black};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
    text-transform: none;
    width: 120px;
    margin: 0 10px;
  }
`;

export const RedButton = styled.div`
  button.MuiButton-contained {
    background: ${theme.colors.red};
  }
`;

export const GreyButton = styled.div`
  button.MuiButton-contained {
    background: ${theme.colors.grey};
  }
`;

export const GreenButton = styled.div`
  button.MuiButton-contained {
    background: ${theme.colors.green};
  }
`;

export const SelectInput = styled.div`
  width: 195px;
  .MuiInputBase-root {
    width: 100%;
  }
`;

export const AddDepotField = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 21px 0;
  &:nth-last-of-type(2) {
    margin-bottom: 0;
  }
  label {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
    color: ${theme.colors.black};
    width: 200px;
    text-align: left;
    padding-right: 15px;
  }
  .MuiSelect-select {
    padding: 9.5px 40px 5.5px 15px;
    text-align: left;
  }
  input {
    padding: 9.5px 15px 5.5px;
    text-align: left;
  }
  fieldset {
    border-radius: 6px;
    border: 1px solid ${theme.colors.grey};
  }
`;

// End Modal CSS
