import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { Button, Container } from '@mui/material';
import SeasonalityComponent from '../../components/SeasonalityComponent';
import BusTypeComponent from '../../components/BusTypeComponent';
import AdditionalInformationComponent from '../../components/AdditionalInformationComponent';
import SuccessNotificationComponent from '../../components/SuccessNotificationComponent/index.js';
import { reset, resetUpdateConfig } from '../../redux/management/slice.js';
import { getConfig, updateConfig } from '../../redux/management/index.js';
import { EditIcon, SaveIcon, CrossIcon } from '../../assets/icon';
import { EditButton } from './styled.js';

const ManagementPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClassActive, setClassActive] = useState(false);
  const { config, updateConfigDetail } = useSelector(
    (state) => state.management,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEmpty(updateConfigDetail) && !isEmpty(updateConfigDetail?.message)) {
      setIsVisible(true);
      dispatch(getConfig());
      setTimeout(() => {
        setIsVisible(false);
      }, 5000);
    } else {
      setIsVisible(false);
    }
  }, [updateConfigDetail]);

  const handleEdit = (e) => {
    e.preventDefault();
    dispatch(resetUpdateConfig());
    setClassActive(!isClassActive);
  };
  const handleUpdateConfig = (values) => {
    delete values?.quantity;
    delete values?.max_number_passenger;
    delete values?.daily_price;
    delete values?.bus_type;
    dispatch(updateConfig({ ...values }));
    setClassActive(!isClassActive);
  };
  useEffect(() => {
    dispatch(getConfig());
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <>
      {!isEmpty(config) && (
        <Formik initialValues={config} onSubmit={handleUpdateConfig}>
          {({ handleSubmit, resetForm }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <EditButton>
                {!isClassActive ? (
                  <Button variant="contained" onClick={handleEdit}>
                    <EditIcon />
                    {t('Management.Edit')}
                  </Button>
                ) : (
                  <div>
                    <Button
                      variant="contained"
                      type="submit"
                      className="save-btn"
                    >
                      <SaveIcon />
                      {t('Management.Save')}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        resetForm();
                        setClassActive(!isClassActive);
                      }}
                      className="cancel"
                    >
                      <CrossIcon />
                      {t('Management.Cancel')}
                    </Button>
                  </div>
                )}
              </EditButton>
              <Container maxWidth={false}>
                {isVisible && <SuccessNotificationComponent />}
                <BusTypeComponent isClassActive={isClassActive} />
                <SeasonalityComponent isClassActive={isClassActive} />
                <AdditionalInformationComponent isClassActive={isClassActive} />
              </Container>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default ManagementPage;
