import React, { useEffect } from 'react';
import CompaniesListComponent from '../../components/CompaniesListComponent/index.js';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

const AdminPage = () => {
  const { userDetails } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(userDetails) && userDetails?.role == 'BusPartner') {
      navigate('/price-calculation');
    }
  }, [userDetails]);

  return (
    <>
      <CompaniesListComponent />
    </>
  );
};

export default AdminPage;
