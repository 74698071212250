import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Box, Paper, IconButton, Typography, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { authGet, authPost } from '../../../services/auth';

const ChatInterface = () => {
  // Get chatId from path param
  const { tripRequestCode } = useParams();

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const editorRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    console.log('Trip request code');
    authGet({
      route: `v1/conversations/trip-requests/${tripRequestCode}/bus-partner`,
    }).then((result) => {
      setIsLoading(false);
      setMessages(
        result.data.map((message) => ({
          id: message.id,
          text: message.message,
          timestamp: message.creationDate,
          isSent: message.direction === 0,
        })),
      );
    });
    console.log(messagesEndRef.current);
  }, [tripRequestCode]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
    setIsLoading(true);

    const currentTime = new Date().toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    await authPost({
      route: `v1/conversations/trip-requests/${tripRequestCode}/bus-partner-send`,
      body: {
        message: newMessage,
        clientTimestamp: new Date().toISOString(),
      },
    }).then((_) => {
      setIsLoading(false);
      setNewMessage('');
      console.log(editorRef.current);
      editorRef.current?.setContent('');
      setMessages((prev) => [
        ...prev,
        {
          id: prev[prev.length - 1].id + 1,
          text: newMessage,
          timestamp: currentTime,
          isSent: true,
        },
      ]);
    });
  };

  return (
    <Container maxWidth="md" sx={{ height: '100%', py: 2 }}>
      <Paper
        border={1}
        sx={{
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            bgcolor: 'white',
          }}>
          {messages.map((message, index) => (
            <Box
              ref={index === messages.length - 1 ? messagesEndRef : null}
              key={message.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: message.isSent ? 'flex-end' : 'flex-start',
                maxWidth: '70%',
                alignSelf: message.isSent ? 'flex-end' : 'flex-start',
              }}>
              <Paper
                elevation={1}
                sx={{
                  p: 1,
                  bgcolor: message.isSent ? '#f5f5f5' : 'white',
                  color: message.isSent ? 'black' : 'black',
                  borderRadius: 2,
                  borderTopRightRadius: message.isSent ? 0 : 2,
                  borderTopLeftRadius: message.isSent ? 2 : 0,
                }}>
                <div dangerouslySetInnerHTML={{ __html: message.text }} />
              </Paper>
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  color: 'text.secondary',
                }}>
                {message.timestamp}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            p: 2,
            bgcolor: 'background.paper',
            borderTop: 1,
            borderColor: 'divider',
          }}>
          <Box
            sx={{
              display: 'flex',
              minHeight: '150px',
              gap: 1,
              flexDirection: 'row',
            }}>
            <Box
              sx={{
                flexGrow: 1,
              }}>
              <Editor
                onInit={(_evt, editor) => (editorRef.current = editor)}
                tinymceScriptSrc="/tinymce/tinymce.min.js"
                licenseKey={'gpl'}
                onEditorChange={(content, editor) => {
                  setNewMessage(content);
                }}
                init={{
                  height: 140,
                  language_url: '/tinymce/lang/it.js',
                  language: 'it',
                  menubar: false,
                  plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'preview',
                    'wordcount',
                  ],
                  toolbar:
                    'undo redo | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />
            </Box>
            <IconButton
              color="primary"
              onClick={handleSendMessage}
              disabled={
                isLoading || newMessage == null || newMessage.trim() === ''
              }
              sx={{
                bgcolor: 'primary.main',
                color: 'white',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
                '&.Mui-disabled': {
                  bgcolor: 'action.disabledBackground',
                  color: 'action.disabled',
                },
                width: 48, // Set a fixed width for the button
                height: 48, // Set a fixed height for the button
                alignSelf: 'flex-start',
              }}>
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ChatInterface;
