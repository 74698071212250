import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 767px) {
    display: block;
  }
  h2 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: ${theme.colors.black};
    line-height: normal;
    margin: 0;
  }
  h3 {
    font-size: 22px;
    font-weight: 400;
    color: ${theme.colors.red};
    margin: 0;
    span {
      font-size: 20px;
      font-weight: 700;
      background-color: ${theme.colors.red};
      color: ${theme.colors.white};
      padding: 5px 3px;
      border-radius: 5px;
    }
  }
`;
export const CustomEmailBox = styled.div`
  border-radius: 21px;
  border: 1px solid ${theme.colors.grey};
  padding: 30px;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    padding: 30px 15px;
  }
  .MuiGrid-item {
    margin: 0 0 0 0;
    padding-left: 26px;
  }
  h6 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
    color: ${theme.colors.black};
    margin: 0 0 15px 0;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  span {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.36px;
    color: ${theme.colors.black};
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  a {
    color: ${theme.colors.black};
    text-decoration: none;
    transition: 0.3s all;
    &:hover {
      color: ${theme.colors.primary};
    }
  }
`;
export const EditButton = styled.div`
  text-align: right;
  position: sticky;
  top: 0;
  background: ${theme.colors.white};
  padding: 10px 0;
  z-index: 9;
  @media screen and (max-width: 991px) {
    padding: 10px 15px;
  }
  button {
    border-radius: 26.5px;
    background: ${theme.colors.black};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    padding: 12px 22px;
    width: 140px;
    svg {
      margin-right: 5px;
      width: 22px;
      height: 22px;
    }
    &.save-btn {
      background: ${theme.colors.green};
      svg {
        margin-right: 12px;
      }
    }
    &.cancel {
      background: ${theme.colors.grey3};
      margin: 0 0 0 10px;
    }
  }
`;
