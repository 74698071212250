import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI, userSignUp } from '../../services/auth';

export const handleLogin = createAsyncThunk(
  'handleLogin',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'login',
        body: params.body,
        isAuthenticated: false,
      });
      if (response?.data && !response.data.error) {
        params.useAuthContext.onLogin();
      }
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const handleSignup = createAsyncThunk(
  'handleSignup',
  async (params, { rejectWithValue }) => {
    const response = await userSignUp({
      route: 'register/bus-partner',
      body: params.body,
    });
    if (!response) return rejectWithValue();
    return { response };
  },
);

export const refreshToken = async () => {
  const authToken = localStorage.getItem('authToken');
  const authTokenAdmin = localStorage.getItem('authTokenAdmin');
  if (authToken !== '' && authTokenAdmin !== '') return false;
  const response = await callPostAPI({
    route: 'refresh-token',
    isAdmin: authTokenAdmin ? true : false,
  });
  return response;
};

export const handleLogOut = createAsyncThunk(
  'handleLogOut',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({ route: 'logout', body: params });
      if (response?.data && !response.data.error) {
        params.useAuthContext.onLogout();
      }
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const handleAdminLogOut = createAsyncThunk(
  'handleAdminLogOut',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'logout',
        body: params,
        isAdmin: true,
      });
      if (response?.data && !response.data.error) {
        params.useAuthContext.onLogout();
      }
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const forgotPassword = createAsyncThunk(
  'forgotPassword',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'forget-password',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const ResetPassword = createAsyncThunk(
  'ResetPassword',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'reset-password',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const acceptTerms = createAsyncThunk(
  'acceptTerms',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'accept-terms',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMailTemplate = createAsyncThunk(
  'getMailTemplate',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callGetAPI({ route: 'mail-template', params });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateMailTemplate = createAsyncThunk(
  'updateMailTemplate',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'mail-template',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const paymentTermsConditionAccept = createAsyncThunk(
  'paymentTermsConditionAccept',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'payment-terms-conditions-accepted',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserDetails = createAsyncThunk(
  'getUserDetails',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callGetAPI({ route: 'user-details', params });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createConnectLink = createAsyncThunk(
  'createConnectLink',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callGetAPI({
        route: 'connect-create-account',
        params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const changeStripeStatus = createAsyncThunk(
  'changeStripeStatus',
  async (params, { rejectWithValue }) => {
    try {
      const response = await callPostAPI({
        route: 'active-stripe-payment',
        body: params,
      });
      return { response };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
