import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getBusPartnerDetail } from '../../redux/company';

const BusPartnerHeaderComponent = () => {
  const navigate = useNavigate();
  const { busPartnerDetail } = useSelector((state) => state.company);
  const { busPartnerCode } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (busPartnerCode) {
      dispatch(getBusPartnerDetail({ unique_code: busPartnerCode }));
    }
  }, []);
  return (
    <AppBar component="nav" color="transparent" position="static">
      <Toolbar>
        <Typography
          className="headerTitle"
          //onClick={() => navigate(`/${company?.unique_code}/ ? wwww.quotabus.com`)}
          onClick={() => {
            if (busPartnerDetail?.name) {
              navigate(`/${busPartnerDetail.unique_code}/`);
            } else {
              window.open('https://quotabus.com/', '_blank');
            }
          }}
          component="div"
          variant="h6"
          sx={{
            my: 1,
            mx: 2,
            fontSize: '30px',
            fontWeight: '500',
            color: '#000',
            flexGrow: 1,
          }}
        >
          {busPartnerDetail?.name || 'Quotabus'}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default BusPartnerHeaderComponent;
