import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const MainBox = styled.div`
  max-width: 545px;
  margin: 80px auto 40px;
  border-radius: 40px;
  border: 1px solid ${theme.colors.grey};
  padding: 0 30px 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  ${theme.breakPoint.tablet} {
    margin: 40px auto 40px;
    border-radius: 30px;
    padding: 0 20px 30px;
  }
`;

export const Title = styled.div`
  padding: 40px 0 30px 0;
  text-align: center;
  ${theme.breakPoint.tablet} {
    padding: 40px 0 20px;
  }
  h1 {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.8px;
    color: ${theme.colors.black};
    padding-left: 15px;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: ${theme.colors.black};
  }
`;
export const CheckMark = styled.div`
  width: 30px;
  height: 30px;
  background: ${theme.colors.green};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HeadingWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
`;
