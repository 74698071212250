import styled from '@emotion/styled';
import theme from '../../../themes/base.json';

export const MainBox = styled.div`
  max-width: 545px;
  margin: 80px auto 40px;
  border-radius: 40px;
  border: 1px solid ${theme.colors.grey};
  padding: 0 30px 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  ${theme.breakPoint.tablet} {
    margin: 40px auto 40px;
    border-radius: 30px;
    padding: 0 20px 30px;
  }
`;

export const Title = styled.div`
  padding: 40px 0 30px 0;
  text-align: center;
  ${theme.breakPoint.tablet} {
    padding: 40px 0 20px;
  }
  h1 {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.8px;
    color: ${theme.colors.black};
  }
`;

export const UserForm = styled.div`
  text-align: center;
  margin: 0 auto;
  .MuiFormControl-root {
    margin: 0 0 20px 0;
    button {
      padding: 0;
      min-width: unset;
      svg {
        fill: ${theme.colors.grey};
      }
    }
  }
  .Mui-error {
    font-size: 16px;
    text-transform: capitalize;
  }
  input {
    padding: 16px 24px 16px 10px;
    color: ${theme.colors.black};
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    &::placeholder {
      color: ${theme.colors.grey};
      opacity: 1;
      text-transform: uppercase;
    }
  }
  svg {
    width: 28px;
  }
  fieldset {
    border-radius: 13px;
    border: 1px solid ${theme.colors.grey};
  }
  .MuiFormControlLabel-root {
    display: flex;
    .MuiFormControlLabel-label {
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      color: ${theme.colors.grey};
      text-transform: uppercase;
      margin-top: 4px;
    }
  }
  button {
    &.MuiButton-contained {
      width: 100%;
      padding: 20px;
      margin-top: 30px;
      border-radius: 13px;
      background: ${theme.colors.primary};
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
      color: ${theme.colors.white};
    }
  }
`;

export const ButtonText = styled.div`
    padding: 10px 0;
    text-align: right;
    button {
        line-height: normal;
        text-transform: none;
        font-size: 22px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 10px;
    }
  }
`;
