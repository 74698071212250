import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { extractDetails, getEuropeCountries } from '../../utils/helper';
import { TextField } from '@mui/material';

const AddressInput = (props) => {
  const { values, errors, touched, setFieldValue, handleBlur, handleChange } =
    props;
  const { ref: materialRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE,
    onPlaceSelected: (place) => {
      const googleAddress = extractDetails(place.address_components);
      const address = {
        lat: place.geometry.location.lat(),
        lon: place.geometry.location.lng(),
        address: place.formatted_address,
        ...googleAddress,
      };
      setFieldValue('lat', address?.lat);
      setFieldValue('lon', address?.lon);
      setFieldValue('address', address?.address);
      setFieldValue('post_code', address?.post_code);
      setFieldValue('country', address?.country);
      setFieldValue('city', address?.city);
    },
    inputAutocompleteValue: 'formatted_address',
    options: {
      // fields : ['address_components', 'formatted_address', 'geometry.location', 'place_id', 'name'],
      types: [],
      componentRestrictions: { country: getEuropeCountries() },
    },
  });
  return (
    <TextField
      name="address"
      inputRef={materialRef}
      value={values?.address}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={touched?.address && errors?.address}
      error={Boolean(touched?.address && errors.address)}
    />
  );
};
export default AddressInput;
