import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const Sidebar = styled.div`
  background: ${theme.colors.grey4};
  height: 100%;
  @media screen and (max-width: 991px) {
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-between;
    height: auto;
  }
  ul {
    padding: 0;
    @media screen and (max-width: 991px) {
      padding: 0 15px;
    }
    a.MuiListItemButton-root {
      border-radius: 5px;
      background: transparent;
      padding: 10.5px 14px;
      &.Mui-selected {
        border-radius: 5px;
        background: ${theme.colors.primary};
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: ${theme.colors.white};
        svg {
          path {
            fill: ${theme.colors.white};
          }
          g {
            rect {
              fill: ${theme.colors.white};
            }
          }
        }
      }
      &.mobilehide {
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
    }
    button {
      width: 100%;
      padding: 10.5px 14px;
      border-radius: 5px;
      text-transform: none;
      &:hover {
        background: transparent;
      }
    }
    span {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
      padding-left: 12px;
    }
  }
`;
export const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s all;
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
  }
  &.active {
    visibility: visible;
    opacity: 1;
  }
`;
export const HamburgerIcon = styled.div`
  cursor: pointer;
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
  }
  hr {
    height: 2px;
    width: 30px;
    background: ${theme.colors.primary};
    border: none;
    margin: 5px 0;
  }
`;
export const MenuClose = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin: 20px 10px 20px auto;
  display: none;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    display: block;
  }
  &:before {
    position: absolute;
    right: 15px;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: ${theme.colors.primary};
    transform: rotate(45deg);
  }
  &:after {
    position: absolute;
    right: 15px;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: ${theme.colors.primary};
    transform: rotate(-45deg);
  }
`;
export const MainMenu = styled.div`
  padding: 0 36px;
  height: calc(100vh - 120px);
  overflow: auto;
  @media screen and (max-width: 991px) {
    height: auto;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 414px;
    background: ${theme.colors.grey4};
    z-index: 99;
    transition: 0.4s all;
    transform: translateX(100%);
    &.active {
      transform: translateX(0);
    }
  }
`;

export const SidebarLogo = styled.div`
  padding: 27px 13px 50px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 991px) {
    padding: 0;
  }
  svg {
    width: 164px;
    padding-left: 13px;
    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
    path {
      fill: ${theme.colors.primary};
      &:last-child {
        fill: ${theme.colors.lightGreen};
      }
    }
  }
`;

export const MenuIconItem = styled.div`
  width: 31px;
  text-align: center;
  line-height: normal;
`;

export const BackArrow = styled.div`
  display: flex;
  align-items: center;
  background: ${theme.colors.lightPurple};
  padding: 13px 19px 13px 36px;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  h5 {
    color: ${theme.colors.black};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
  }
  svg {
    width: 25px;
    height: 25px;
  }
`;
export const ReportBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 19px 13px 36px;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  button {
    border-radius: 26.5px;
    background: ${theme.colors.grey3};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    padding: 12px 22px;
    width: 140px;
    svg {
      color: ${theme.colors.white};
      margin-right: 5px;
      width: 22px;
      height: 22px;
    }
    &:hover {
      background: ${theme.colors.grey3};
    }
  }
`;
// Modal CSS

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 732px;
  border-radius: 10px;
  background: ${theme.colors.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  outline: none;
  h3 {
    padding: 10px;
  }
`;
export const ModalHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 8px 22px 7px 22px;
  border-bottom: 1px solid ${theme.colors.grey};
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    color: ${theme.colors.black};
  }
`;
export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid ${theme.colors.grey};
  border-radius: 100px;
  background: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;
export const ModalContentData = styled.div`
  padding: 22px;
  text-align: center;
  h3 {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    color: ${theme.colors.black};
  }
  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${theme.colors.black};
    letter-spacing: -0.02em;
    padding: 1em;
  }
`;
export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: center;
  gap: 24px;
  button.MuiButton-contained {
    border-radius: 6px;
    gap: 10px;
    color: ${theme.colors.white};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-transform: none;
    letter-spacing: -0.472px;
    padding: 13px 15px;
    line-height: normal;
    min-width: 144px;
    min-height: 46px;
  }
`;
export const GreenButton = styled.div`
  button.MuiButton-contained {
    background: ${theme.colors.green};
  }
`;

export const GreyButton = styled.div`
  button.MuiButton-contained {
    background: ${theme.colors.grey};
  }
`;
