/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { t } from 'i18next';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
} from '@mui/material';
import {
  CloseButton,
  GreenButton,
  GreyButton,
  ModalButtons,
  ModalContent,
  ModalContentData,
  ModalHeader,
} from './styled';
import { CloseIcon } from '../../assets/icon';
import { useAuth } from '../../utils/helper';
import { acceptTerms, handleAdminLogOut, handleLogOut } from '../../redux/auth';
import { useDispatch, useSelector } from 'react-redux';
const TermsAcceptancePopup = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(true);
  const [confirmBox1Checked, setConfirmBox1Checked] = useState(false);
  const [confirmBox2Checked, setConfirmBox2Checked] = useState(false);
  const { userDetails } = useSelector((state) => state.auth);
  const useAuthContext = useAuth();
  const dispatch = useDispatch();

  const handleCheckboxChange = (checkboxNumber) => {
    if (checkboxNumber === 1) {
      setConfirmBox1Checked(!confirmBox1Checked);
    } else if (checkboxNumber === 2) {
      setConfirmBox2Checked(!confirmBox2Checked);
    }
  };

  const handleAccept = () => {
    if (confirmBox1Checked && confirmBox2Checked) {
      dispatch(acceptTerms());
    }
  };
  const handleLogOutSubmit = () => {
    if (userDetails?.role == 'Admin') {
      dispatch(handleAdminLogOut({ useAuthContext }));
    } else {
      dispatch(handleLogOut({ useAuthContext }));
    }
  };
  return (
    <Modal
      disableEscapeKeyDown
      open={
        isAddModalOpen &&
        userDetails?.role !== 'Admin' &&
        !userDetails?.terms_accepted
      }
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          setIsAddModalOpen(false);
        }
      }}
      aria-labelledby="terms-acceptance"
      aria-describedby="terms-acceptance-body"
    >
      <ModalContent>
        <ModalHeader>
          <Typography id="terms-acceptance" variant="h3">
            {t('TermsAcceptance.Title')}
          </Typography>
          {/* <CloseButton onClick={() => setIsAddModalOpen(false)}><CloseIcon /></CloseButton> */}
        </ModalHeader>
        <ModalContentData id="terms-acceptance-body">
          <Typography variant="h5">{t('TermsAcceptance.Heading')}</Typography>
          <Typography>{t('TermsAcceptance.Detail1')}</Typography>
          <Typography>{t('TermsAcceptance.Detail2')}</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmBox1Checked}
                  onChange={() => handleCheckboxChange(1)}
                />
              }
              label={
                <>
                  {t('TermsAcceptance.ConfirmBox1')}{' '}
                  <a target="_blank" href="/terms-conditions">
                    {t('TermsAcceptance.Page1')}
                  </a>{' '}
                  {t('General.Quotabus')}
                </>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmBox2Checked}
                  onChange={() => handleCheckboxChange(2)}
                />
              }
              label={
                <>
                  {t('TermsAcceptance.ConfirmBox2')}{' '}
                  <a target="_blank" href="/privacy-policy">
                    {t('TermsAcceptance.Page2')}
                  </a>{' '}
                  {t('General.Quotabus')}
                </>
              }
            />
          </FormGroup>
          <ModalButtons>
            <GreyButton>
              <Button onClick={() => handleLogOutSubmit()} variant="contained">
                {t('TermsAcceptance.Cancel')}
              </Button>
            </GreyButton>
            <GreenButton>
              <Button
                onClick={handleAccept}
                variant="contained"
                disabled={!confirmBox1Checked || !confirmBox2Checked}
              >
                {t('TermsAcceptance.Confirm')}
              </Button>
            </GreenButton>
          </ModalButtons>
        </ModalContentData>
      </ModalContent>
    </Modal>
  );
};

export default TermsAcceptancePopup;
