import React, { useEffect, useState } from 'react';
import { callGetAPI } from '../../services/auth';
import TripStopsSummary, { TripItinerary } from './index';

export const TripStopsSummaryWithData = ({ tripRequestCode }) => {
  const [tripData, setTripData] = useState();

  useEffect(() => {
    getTripRequestData({ tripRequestCode }).then((response) => {
      console.log(response);
      setTripData(response?.data?.data);
    });
  }, [tripRequestCode]);

  return (
    <>
      <TripStopsSummary tripData={tripData} />
      <TripItinerary tripData={tripData} />
    </>
  );
};

const getTripRequestData = async ({ tripRequestCode }) => {
  return await callGetAPI({
    route: `/trip-requests/${tripRequestCode}/details`,
  });
};

export default TripStopsSummaryWithData;
