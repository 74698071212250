import { useParams, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import TripStopsSummaryWithData from '../../components/TripStopsSummary/dataWrapper';
import Chat from '../chat/Chat';

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { BackButton } from './styled';
import { ArrowBack } from '@mui/icons-material';
import { t } from 'i18next';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const TripRequestConversation = () => {
  const navigate = useNavigate();
  const { tripRequestCode } = useParams();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <>
          <Grid item xs={12}>
            <BackButton>
              <Button variant="contained" onClick={() => navigate(-1)}>
                <ArrowBack />
                {t('PriceCalc.Button.Back')}
              </Button>
            </BackButton>
          </Grid>

          <Grid item xs={7}>
            <Chat />
          </Grid>
          <Grid item xs={5}>
            <TripStopsSummaryWithData
              tripRequestCode={tripRequestCode}
              busPartnerCode={'YRLX67BN'}
            />
          </Grid>
        </>
      </Grid>
    </Box>
  );
};

export default TripRequestConversation;
