import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { t } from 'i18next';
import { isEmpty, upperFirst } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  HeadPart,
  RightPart,
  TableContent,
  DateSelect,
  DateBox,
  ModalContent,
  ModalHeader,
  CloseButton,
  ModalContentData,
  ActionButtons,
  ShareButtons,
} from './styled';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { CloseIcon, EmailIcon, LinkIcon, ShareIcon } from '../../assets/icon';
import { reset } from '../../redux/reqhistory/slice';
import {
  customerNotify,
  getHistory,
  sendOfferToSupplier,
  updateRequestStatus,
} from '../../redux/reqhistory';
import { getClientNotifyStatus } from '../../utils/helper';
import useDebounce from '../../utils/useDebounceHook';

dayjs.extend(updateLocale);
dayjs.updateLocale('it', {
  weekStart: 1,
});
const EstimateComponent = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { userDetails, busPartner } = useSelector((state) => state.auth);
  const {
    loading,
    customerNotifyDetail,
    customerNotifyError,
    requestHistoryDetail,
    updateReqStatusDetail,
    sendOfferToSupplierDetail,
    sendOfferToSupplierError,
  } = useSelector((state) => state.reqhistory);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchStatus, setSearchStatus] = useState([]);
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [requestID, setrequestID] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [receivers, setReceivers] = useState([]);
  const [shareTrip, setShareTrip] = useState({});
  const [newStatus, setNewStatus] = useState('');
  const [alertMsg, setAlertMsg] = useState({ msg: '', type: 'success' });
  const status = [
    'created',
    'declined',
    'offered',
    'accepted',
    'confirmed',
    'partpaid',
    'paid',
  ];
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    handleHistroyFilter();
    return () => {
      dispatch(reset());
    };
  }, [
    startDate,
    endDate,
    page,
    debouncedSearchTerm,
    searchStatus,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (!isEmpty(updateReqStatusDetail)) {
      if (
        !getClientNotifyStatus().includes(updateReqStatusDetail?.updatedStatus)
      ) {
        handleHistroyFilter();
      } else {
        setIsAddModalOpen(true);
      }
    }
  }, [updateReqStatusDetail]);

  useEffect(() => {
    if (!isEmpty(customerNotifyError)) {
      setAlertMsg({
        msg: customerNotifyError?.message,
        type: 'error',
      });
      setOpen(true);
      setIsAddModalOpen(false);
      handleHistroyFilter();
    }
    if (!isEmpty(customerNotifyDetail)) {
      setAlertMsg({
        msg: t('ClientNotify.NotifySuccessMsg'),
        type: 'success',
      });
      setOpen(true);
      setIsAddModalOpen(false);
      handleHistroyFilter();
    }
  }, [customerNotifyDetail, customerNotifyError]);
  useEffect(() => {
    if (!isEmpty(sendOfferToSupplierDetail)) {
      setAlertMsg({
        msg: t('Offer.sendOfferSuccess'),
        type: 'success',
      });
      setOpen(true);
      setShareModalOpen(false);
      setReceivers([]);
    }
    if (!isEmpty(sendOfferToSupplierError)) {
      setAlertMsg({
        msg: t('Offer.sendOfferError'),
        type: 'error',
      });
      setOpen(true);
      setShareModalOpen(false);
      setReceivers([]);
    }
  }, [sendOfferToSupplierDetail, sendOfferToSupplierError]);
  const handleHistroyFilter = () => {
    let payload = {
      is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0,
      rows_per_page: rowsPerPage,
      page: page + 1,
    };
    if (debouncedSearchTerm) {
      payload = { ...payload, search_request_id: debouncedSearchTerm };
    }
    if (startDate) {
      payload = { ...payload, start_date: startDate.format('YYYY-MM-DD') };
    }
    if (endDate) {
      payload = { ...payload, end_date: endDate.format('YYYY-MM-DD') };
    }
    if (!isEmpty(searchStatus)) {
      payload = { ...payload, status_filter: searchStatus.join(',') };
    }
    dispatch(getHistory(payload));
  };
  const handleStatusFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setSearchStatus(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    const selectedLabels = value.map((val) =>
      t(`General.${upperFirst(status.find((item) => item === val))}`),
    );
    setSelectedLabels(selectedLabels);
  };
  const handleStatusChange = (event, record) => {
    setrequestID(record.trip_request_code);
    setEmail(record.requestor_email);
    const payload = {
      is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0,
      trip_request_id: record.id,
      status: event.target.value,
    };
    dispatch(updateRequestStatus(payload));
    setNewStatus(event.target.value);
  };
  const handleCloseModal = () => {
    setIsAddModalOpen(false);
    handleHistroyFilter();
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i;
    if (!emailRegex.test(e.target.value)) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const handleCustomerNotify = () => {
    const payload = {
      trip_request_code: requestID,
      status: newStatus,
      requestor_email: email,
    };
    dispatch(customerNotify(payload));
  };
  const handleShareOffer = (trip) => {
    setShareModalOpen(true);
    setShareTrip(trip);
  };
  const handleShareCloseModal = () => {
    setShareModalOpen(false);
  };
  const handleCopyButton = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-undef
    const text = `${window.location.origin}/${busPartner?.unique_code}/offer/${shareTrip?.trip_request_code}`;
    navigator.clipboard
      .write([
        // eslint-disable-next-line no-undef
        new ClipboardItem({
          'text/plain': new Blob([text], { type: 'text/plain' }),
        }),
      ])
      .then(() => {
        setAlertMsg({
          msg: t('Offer.OfferLinkCopiedSuccess'),
          type: 'success',
        });
      })
      .catch(() => {
        setAlertMsg({
          msg: t('Offer.OfferLinkCopiedError'),
          type: 'error',
        });
      })
      .finally(() => {
        setOpen(true);
        setShareModalOpen(false);
        setReceivers([]);
      });
  };
  const handleSendOfferToSupplier = () => {
    const payload = {
      trip_request_code: shareTrip?.trip_request_code,
      suppliers_email: receivers,
    };
    dispatch(sendOfferToSupplier(payload));
  };
  // Function to validate email format
  const validateEmail = (email) => {
    // Basic email regex pattern for validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // Handle changes when user types or adds a new email
  const handleChange = (event, value) => {
    // Split the value on spaces and commas, trim any extra spaces, and filter out invalid emails
    const validEmails = value
      .map((v) => v.trim())
      .filter((v) => validateEmail(v));

    setReceivers(validEmails);
  };

  // Handle key press events to detect space key
  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      const inputValue = event.target.value.trim();
      if (validateEmail(inputValue)) {
        setReceivers((prevReceivers) => [...prevReceivers, inputValue]);
        setInputValue(''); // Clear the input after adding a valid email
        event.target.value = ''; // Clear the input after adding a valid email
      } else {
        setInputValue('');
        event.target.value = ''; // Clear the input after adding a valid email
      }
      event.preventDefault(); // Prevent default space behavior
    }
  };
  return (
    <>
      <HeadPart>
        <Typography component="h2">{t('Requests.Title')}</Typography>
        <RightPart>
          <FormControl>
            <Select
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              name="status_filter"
              multiple
              value={searchStatus}
              onChange={handleStatusFilterChange}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <span style={{ color: 'grey' }}>
                      {t('Requests.SearchStatus')}
                    </span>
                  );
                }
                return selectedLabels.join(', ');
              }}
              MenuProps={{
                classes: {
                  paper: 'custom_status_filter_list', // Add your custom class to the dropdown list
                },
              }}>
              {status.map((name) => (
                <MenuItem key={name} value={name} className={name}>
                  <Checkbox checked={searchStatus.indexOf(name) > -1} />
                  <ListItemText primary={t(`General.${upperFirst(name)}`)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name="search"
            placeholder={t('Requests.SearchRequestID')}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
            <DateSelect>
              <DateBox>
                <InputLabel>{t('Requests.StartDate')}</InputLabel>
                <DatePicker
                  disableFuture
                  name="startDate"
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  format="DD/MM/YYYY"
                  onChange={(value) => {
                    setStartDate(dayjs(value));
                  }}
                />
              </DateBox>
              <DateBox>
                <InputLabel>{t('Requests.EndDate')}</InputLabel>
                <DatePicker
                  disableFuture
                  name="endDate"
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  format="DD/MM/YYYY"
                  onChange={(value) => {
                    setEndDate(dayjs(value));
                  }}
                />
              </DateBox>
            </DateSelect>
          </LocalizationProvider>
        </RightPart>
      </HeadPart>
      <TableContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('Requests.ID')}</TableCell>
                <TableCell>{t('Requests.DateTime')}</TableCell>
                <TableCell>{t('Requests.Channel')}</TableCell>
                <TableCell>{t('Requests.City')}</TableCell>
                <TableCell>{t('Requests.DepatureDate')}</TableCell>
                <TableCell>{t('Requests.Status')}</TableCell>
                <TableCell>{t('Requests.Pax')}</TableCell>
                <TableCell>{t('Requests.TotalPrice')}</TableCell>
                <TableCell>{t('Requests.Action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requestHistoryDetail?.results?.map((record, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Link
                        className="detailLink"
                        target="_blank"
                        rel="noreferrer"
                        href={`/request/${record.trip_request_code}`}>
                        {record.trip_request_code}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {dayjs(record.creation_date).format('DD/MM/YYYY HH:mm')}
                    </TableCell>
                    <TableCell>
                      {t(`General.${upperFirst(record.source)}`)}
                    </TableCell>
                    <TableCell>{record.departure_city}</TableCell>
                    <TableCell>
                      {dayjs(record.departure_date).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                        <Select
                          displayEmpty
                          inputProps={{ 'aria-label': 'Status label' }}
                          className={record.status}
                          value={record.status || ''}
                          onChange={(e) => handleStatusChange(e, record)}
                          MenuProps={{
                            classes: {
                              paper: 'payment_custom_status_filter_list',
                            },
                          }}>
                          {status.map((name) => (
                            <MenuItem key={name} value={name} className={name}>
                              {t(`General.${upperFirst(name)}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>{record.passenger_number}</TableCell>
                    <TableCell>{record.included_price_rounded}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => handleShareOffer(record)}>
                        {t('Requests.Share')}
                        <ShareIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContent>
      <TablePagination
        component="div"
        count={requestHistoryDetail?.total_records || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* Start Notify Client for Status Change */}
      <Modal
        open={isAddModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="client-notify"
        aria-describedby="client-notify-body">
        <ModalContent>
          <ModalHeader>
            <Typography id="client-notify" variant="h3">
              {t('ClientNotify.ModalTitle')}
            </Typography>
            <CloseButton onClick={handleCloseModal}>
              <CloseIcon />
            </CloseButton>
          </ModalHeader>
          <ModalContentData id="client-notify-body">
            <React.Fragment>
              {t('ClientNotify.ModelContent')
                .split('[Status]')
                .map((part, index) => (
                  <React.Fragment key={index}>
                    {index !== 0 ? (
                      <span style={{ fontWeight: 'bold' }}>
                        {t(`General.${upperFirst(newStatus)}`)}
                      </span>
                    ) : null}
                    {part}
                  </React.Fragment>
                ))}
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                sx={{ marginTop: '20px', padding: '0px 15px 0px 15px' }}>
                <TextField
                  sx={{ borderRadius: '6px', border: '1px' }}
                  placeholder={t('PriceCalc.Email')}
                  error={error}
                  helperText={error ? 'Invalid email format' : ''}
                  type="email"
                  name="requestor_email"
                  onChange={handleChangeEmail}
                  value={email}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <ActionButtons className="generalButton">
                  {loading && (
                    <CircularProgress
                      color="warning"
                      size={24}
                      thickness={5}
                      sx={{ marginRight: '10px' }}
                    />
                  )}
                  <Button
                    className="offer-btn"
                    variant="contained"
                    onClick={handleCustomerNotify}
                    disabled={error || isEmpty(email) || loading}>
                    {t('ClientNotify.NotifyButton')}
                  </Button>
                </ActionButtons>
              </Stack>
            </React.Fragment>
          </ModalContentData>
        </ModalContent>
      </Modal>
      {/* End Notify Client for Status Change */}
      {/* Start Share Offer Model */}
      <Modal
        open={isShareModalOpen}
        onClose={handleShareCloseModal}
        aria-labelledby="share-offer"
        aria-describedby="share-offer-body">
        <ModalContent>
          <ModalHeader>
            <Typography id="share-offer" variant="h3">
              {t('ShareOffer.ModalTitle')}
            </Typography>
            <CloseButton onClick={handleShareCloseModal}>
              <CloseIcon />
            </CloseButton>
          </ModalHeader>
          <ModalContentData id="share-offer-body">
            <Typography variant="subtitle1">
              {t('ShareOffer.ModelContent', {
                tripCode: shareTrip.trip_request_code,
              })}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    marginTop: '1em',
                  }}>
                  {t('ShareOffer.SuppliersEmailTitle')}
                </Typography>
                <Autocomplete
                  multiple
                  id="email-filled"
                  options={[]}
                  defaultValue={[]}
                  freeSolo
                  value={receivers}
                  // popupIndicator={<CloseIcon />}
                  // eslint-disable-next-line no-unused-vars
                  inputValue={inputValue} // Controlled input value
                  onInputChange={(event, newInputValue) =>
                    setInputValue(newInputValue)
                  } // Update input value on change
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      return (
                        <Chip
                          key={index}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label={t('ShareOffer.SuppliersEmail')}
                      placeholder={t('ShareOffer.SuppliersEmailPlaceholder')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <ShareButtons>
                  <Stack spacing={1}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {t('ShareOffer.Share')}
                    </Typography>
                    {loading && (
                      <CircularProgress
                        color="warning"
                        size={24}
                        thickness={5}
                        sx={{ marginRight: '10px' }}
                      />
                    )}
                    <Button
                      variant="contained"
                      onClick={handleSendOfferToSupplier}
                      disabled={isEmpty(receivers) || loading}>
                      {t('ShareOffer.ViaEmailButton')} <EmailIcon />
                    </Button>
                    <Typography>{t('ShareOffer.Or')}</Typography>
                    <Button variant="contained" onClick={handleCopyButton}>
                      {t('ShareOffer.CopyLinkButton')} <LinkIcon />
                    </Button>
                  </Stack>
                </ShareButtons>
              </Grid>
            </Grid>
          </ModalContentData>
        </ModalContent>
      </Modal>
      {/* End Share Offer Model */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={() => setOpen(false)}>
        <Alert severity={alertMsg.type} sx={{ width: '100%' }}>
          {alertMsg.msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EstimateComponent;
