import { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { ClientContent, EditButton } from './styled';
import { t } from 'i18next';
import { Button, LinearProgress } from '@mui/material';
import { SaveIcon, EditIcon, CrossIcon } from '../../../assets/icon';

const ClientComponent = ({
  language,
  status,
  originalEmailHtml,
  textToCopyRef,
  onChange,
  emailHtml,
  onPreviewToggle,
}) => {
  const [isPreviewMode, setIsPreviewMode] = useState(true);

  const handlePreviewToggle = () => {
    setIsPreviewMode(!isPreviewMode);
    onPreviewToggle(!isPreviewMode);
  };

  if (isPreviewMode && (status === 'SUCCESS' || status === '')) {
    return (
      <>
        <EditButton>
          <Button variant="contained" onClick={handlePreviewToggle}>
            <EditIcon />
            {t('Management.Edit')}
          </Button>
        </EditButton>
        <ClientContent dangerouslySetInnerHTML={{ __html: emailHtml }} />
      </>
    );
  } else {
    return (
      <>
        <EditButton>
          <Button
            variant="contained"
            type="submit"
            className="save-btn"
            disabled={status === 'PENDING'}
            onClick={() => {
              handlePreviewToggle();
              onChange(textToCopyRef.current.getContent());
            }}>
            <SaveIcon />
            {t('Management.Save')}
          </Button>
          <Button
            className="reset-btn"
            type="submit"
            disabled={status === 'PENDING'}
            onClick={() => {
              textToCopyRef.current.setContent(originalEmailHtml);
              textToCopyRef.current.undoManager.add(false, {
                content: textToCopyRef.current.getContent(),
              });
            }}
            variant="contained">
            {t('PriceCalc.Button.Reset')}
          </Button>
          <Button
            variant="contained"
            disabled={status === 'PENDING'}
            onClick={() => {
              textToCopyRef.current.undoManager.clear();
              handlePreviewToggle();
            }}
            className="cancel">
            <CrossIcon />
            {t('Management.Cancel')}
          </Button>
        </EditButton>
        {status === 'PENDING' && <LinearProgress color="secondary" />}
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          licenseKey={'gpl'}
          disabled={status === 'PENDING'}
          onInit={(_evt, editor) => (textToCopyRef.current = editor)}
          initialValue={emailHtml}
          init={{
            language_url: '/tinymce/lang/it.js',
            language: language.toLowerCase(),
            height: 500,
            menubar: false,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'preview',
              'help',
              'wordcount',
            ],
            toolbar:
              'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
        />
      </>
    );
  }
};

export default ClientComponent;
