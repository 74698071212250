import React from 'react';
import { Container, Link } from '@mui/material';
import HeaderComponent from '../../components/HeaderComponent';

const TermsConditionPage = () => {
  return (
    <>
      <HeaderComponent />
      <Container>
        <p>
          <strong>
            Termini e condizioni generali per l’utilizzo dei siti web di
            Quotabus SRL (di seguito &quot;Quotabus&quot;)
          </strong>
        </p>

        <p>Ultimo aggiornamento: Marzo 11, 2024.</p>

        <p>
          Il sito web di Quotabus, disponibile all&apos;indirizzo e tutti i
          sottodomini associati, &egrave; un&apos;opera protetta da diritto
          d&apos;autore appartenente a Quotabus. Alcune funzionalit&agrave; del
          sito possono essere soggette a linee guida aggiuntive, termini o
          regole, che saranno pubblicate sul sito in connessione con tali
          funzionalit&agrave;. Tutti questi termini aggiuntivi, linee guida e
          regole sono incorporati per riferimento in questi Termini.
        </p>

        <p>
          Questi Termini di Utilizzo descrivono le condizioni legalmente
          vincolanti che regolano l&apos;uso del sito. Accedendo al sito,
          accetti di conformarti alle sue disposizioni. Se non sei
          d&apos;accordo con tutto o parte di queste condizioni, non accedere
          e/o utilizzare il sito.
        </p>

        <p>
          <strong>Accesso al sito</strong>
        </p>
        <p>
          Soggetto a questi Termini, Quotabus ti concede una licenza limitata,
          revocabile, non trasferibile e non esclusiva per accedere al Sito e
          utilizzare il Servizio per scopi commerciali interni. Questo include,
          ma non si limita a, il calcolo automatico dei prezzi, la generazione
          di email automatizzate ai clienti, la visualizzazione di itinerari di
          viaggio e la cronologia delle richieste.
        </p>

        <p>
          <strong>Restrizioni Specifiche</strong>
        </p>
        <p>
          I diritti concessi a te in questi Termini sono soggetti alle seguenti
          restrizioni: (a) non dovrai vendere, affittare, concedere in leasing,
          trasferire, assegnare, distribuire, ospitare o altrimenti sfruttare
          commercialmente il Sito; (b) non dovrai modificare, creare opere
          derivate, smontare, decompilare o reverse-engineer alcuna parte del
          Sito; (c) non dovrai accedere al Sito allo scopo di creare un sito web
          simile o concorrenziale; e (d) tranne quanto espressamente indicato
          qui, nessuna parte del Sito pu&ograve; essere copiata, riprodotta,
          distribuita, ripubblicata, scaricata, visualizzata, postata o
          trasmessa in alcuna forma o con qualsiasi mezzo, a meno che non sia
          diversamente indicato, ogni futuro rilascio, aggiornamento o altra
          aggiunta alle funzionalit&agrave; del Sito sar&agrave; soggetto a
          questi Termini. Tutti i diritti d&apos;autore e altri avvisi di
          propriet&agrave; sul Sito devono essere mantenuti su tutte le copie
          dello stesso.
        </p>

        <p>
          Quotabus si riserva il diritto di modificare, sospendere o
          interrompere il Sito, con o senza preavviso nei tuoi confronti.
          Accetti che Quotabus non sar&agrave; ritenuta responsabile, n&eacute;
          nei tuoi confronti n&eacute; nei confronti di terzi, per qualsiasi
          modifica, sospensione o interruzione del Sito o di parte di esso.
        </p>

        <p>
          <strong>Nessun Obbligo di Supporto o Manutenzione</strong>
        </p>
        <p>
          Accetti che sar&agrave; a esclusiva discrezione di Quotabus decidere
          se fornire o meno supporto in connessione con il Sito, senza alcun
          obbligo da parte sua.
        </p>

        <p>
          <strong>Contenuto Utente</strong>
        </p>
        <p>
          Il &quot;Contenuto Utente&quot; si riferisce a qualsiasi e tutte le
          informazioni e contenuti che un utente invia al Sito. Sei
          esclusivamente responsabile per il tuo Contenuto Utente. Assumi tutti
          i rischi associati all&apos;uso del tuo Contenuto Utente. Con la
          presente certifichi che il tuo Contenuto Utente non viola la nostra
          Politica di Utilizzo Accettabile. Non puoi rappresentare o implicare
          ad altri che il tuo Contenuto Utente sia in qualche modo fornito,
          sponsorizzato o approvato Quotabus. Poich&eacute; la
          responsabilit&agrave; del tuo Contenuto Utente ricade esclusivamente
          su di te, potresti incorrere in responsabilit&agrave;. Quotabus non
          &egrave; obbligata a eseguire il backup di qualsiasi Contenuto Utente
          che pubblichi; inoltre, il tuo Contenuto Utente pu&ograve; essere
          eliminato in qualsiasi momento senza preavviso. Sei l&apos;unico
          responsabile per effettuare le tue copie di backup del tuo Contenuto
          Utente se lo desideri.
        </p>

        <p>
          Con la presente concedi a Quotabus una licenza irreversibile, non
          esclusiva, libera da royalty e completamente pagata, valida in tutto
          il mondo, per riprodurre, distribuire, visualizzare e eseguire
          pubblicamente, preparare opere derivate, incorporare in altre opere, e
          altrimenti utilizzare e sfruttare il tuo Contenuto Utente, e concedere
          sublicenze dei diritti suddetti, esclusivamente ai fini
          dell&apos;inclusione del tuo Contenuto Utente nel Sito. Con la
          presente rinunci irrevocabilmente a qualsiasi rivendicazione e
          affermazione di diritti morali o attribuzione in relazione al tuo
          Contenuto Utente.
        </p>

        <p>
          <strong>Propriet&agrave; Intellettuale</strong>
        </p>
        <p>
          Escludendo qualsiasi Contenuto Utente che potresti fornire, sei
          consapevole che tutti i diritti di propriet&agrave; intellettuale,
          inclusi i diritti d&apos;autore, i brevetti, i marchi e i segreti
          commerciali, nel Sito e nel suo contenuto sono di propriet&agrave; di
          Quotabus o dei suoi fornitori. &Egrave; importante sottolineare che
          questi Termini e il tuo accesso al Sito non ti conferiscono alcun
          diritto, titolo o interesse in o per qualsiasi diritto di
          propriet&agrave; intellettuale, tranne che per quanto espressamente
          indicato. Quotabus e i suoi fornitori si riservano tutti i diritti non
          espressamente concessi in questi Termini.
        </p>

        <p>
          <strong>Politica di Utilizzo Accettabile</strong>
        </p>
        <p>
          I seguenti termini costituiscono la nostra &quot;Politica di Utilizzo
          Accettabile&quot;: Accettate di non utilizzare il Sito per
          raccogliere, caricare, trasmettere, visualizzare o distribuire
          qualsiasi Contenuto Utente (i) che violi diritti di terzi o qualsiasi
          diritto di propriet&agrave; intellettuale o diritto proprietario; (ii)
          che sia illegale, molesto, abusivo, tortuoso, minaccioso, dannoso,
          invasivo della privacy altrui, volgare, diffamatorio, falso,
          intenzionalmente fuorviante, diffamatorio commerciale, pornografico,
          osceno, palesemente offensivo, che promuova razzismo, bigottismo, odio
          o danni fisici di qualsiasi tipo contro qualsiasi gruppo o individuo;
          (iii) che sia dannoso per i minori in qualsiasi modo; o (iv) che violi
          qualsiasi legge, regolamento, o obblighi o restrizioni imposti da
          terzi.
        </p>

        <p>
          Inoltre, accettate di non: (i) caricare, trasmettere o distribuire al
          o attraverso il Sito alcun software destinato a danneggiare o alterare
          un sistema informatico o dati; (ii) inviare attraverso il Sito
          pubblicit&agrave;, materiale promozionale, posta indesiderata, spam,
          catene di Sant&apos;Antonio, schemi piramidali o qualsiasi altra forma
          di messaggi duplicati o non richiesti; (iii) utilizzare il Sito per
          raccogliere, raccogliere, accumulare o assemblare informazioni o dati
          su altri utenti senza il loro consenso; (iv) interferire, disturbare o
          creare un onere eccessivo sui server o sulle reti connesse al Sito, o
          violare i regolamenti, le politiche o le procedure di tali reti; (v)
          tentare di ottenere accesso non autorizzato al Sito, sia tramite
          mining di password o altri mezzi; (vi) molestare o interferire con
          l&apos;uso e il godimento del Sito da parte di altri utenti; o (vi)
          utilizzare software o agenti automatici o script per produrre
          pi&ugrave; account sul Sito, o per generare ricerche, richieste o
          query automatiche al Sito.
        </p>

        <p>
          Ci riserviamo il diritto di rivedere qualsiasi Contenuto Utente, e di
          indagare e/o prendere azioni appropriate contro di voi a nostra
          esclusiva discrezione se violano la Politica di Utilizzo Accettabile o
          qualsiasi altra disposizione di questi Termini o altrimenti create
          responsabilit&agrave; per noi o per qualsiasi altra persona. Tali
          azioni possono includere la rimozione o la modifica del vostro
          Contenuto Utente, la terminazione del vostro Account e/o la
          segnalazione alle autorit&agrave; di legge.
        </p>

        <p>
          Se fornite a Quotabus qualsiasi feedback o suggerimenti riguardo al
          Sito, assegnate qui a Quotabus tutti i diritti in tali feedback e
          accettate che Quotabus abbia il diritto di utilizzare e sfruttare
          pienamente tali feedback e informazioni correlate nel modo che ritiene
          opportuno. Quotabus tratter&agrave; qualsiasi Feedback che fornite
          come non confidenziale e non proprietario.
        </p>
        <p>
          Accettate di tenere indenne e non danneggiare Quotabus e i suoi
          ufficiali, dipendenti e agenti, inclusi i costi e le spese legali, da
          qualsiasi richiesta o domanda fatta da terzi a causa o derivante da
          (a) il vostro uso del Sito, (b) la vostra violazione di questi
          Termini, (c) la vostra violazione delle leggi o regolamenti
          applicabili o (d) il vostro Contenuto Utente. Accettate di non
          risolvere alcuna questione senza il previo consenso scritto di
          Quotabus. Quotabus utilizzer&agrave; sforzi ragionevoli per informarvi
          di tale richiesta, azione o procedimento non appena ne diventa
          consapevole.
        </p>

        <p>
          <strong>Altri Utenti</strong>
        </p>
        <p>
          Ogni utente del Sito &egrave; unicamente responsabile di tutto il
          proprio Contenuto Utente. Poich&eacute; non controlliamo il Contenuto
          Utente, riconoscete e accettate che non siamo responsabili di alcun
          Contenuto Utente, sia che sia fornito da voi che da altri. Accettate
          che Quotabus non sar&agrave; responsabile per alcuna perdita o danno
          subito come risultato di tali interazioni. Se c&apos;&egrave; una
          disputa tra voi e qualsiasi utente del Sito, non siamo obbligati ad
          intervenire.
        </p>

        <p>
          Con la presente, liberate e scaricate per sempre Quotabus e i nostri
          ufficiali, dipendenti, agenti, successori, e rinunciate e cedete, ogni
          e qualsiasi disputa, reclamo, controversia, richiesta, diritto,
          obbligo, responsabilit&agrave;, azione e causa d&apos;azione di ogni
          tipo e natura, che sia sorta o sorga direttamente o indirettamente da,
          o che si riferisca direttamente o indirettamente al Sito.
        </p>
        <p>
          <strong>Cookie e Web Beacon</strong>
        </p>
        <p>
          Come altri siti web, Quotabus utilizza &lsquo;cookie&rsquo;. Questi
          cookie vengono utilizzati per memorizzare informazioni, inclusi le
          preferenze dei visitatori e le pagine sul sito web che il visitatore
          ha accesso o visitato. Le informazioni vengono utilizzate per
          ottimizzare l&apos;esperienza degli utenti personalizzando il
          contenuto delle nostre pagine web in base al tipo di browser dei
          visitatori e/o altre informazioni.
        </p>
        <p>
          <strong>Avvertenze</strong>
        </p>
        <p>
          Il sito &egrave; fornito su base &quot;cos&igrave;
          com&apos;&egrave;&quot; e &quot;come disponibile&quot;. Quotabus e i
          nostri fornitori declinano espressamente tutte le garanzie e
          condizioni di qualsiasi tipo, sia espresse, implicite o statutarie,
          incluse tutte le garanzie o condizioni di commerciabilit&agrave;,
          idoneit&agrave; per uno scopo particolare, titolo, godimento
          tranquillo, accuratezza o non violazione. Noi e i nostri fornitori non
          garantiamo che il sito soddisfer&agrave; le vostre esigenze,
          sar&agrave; disponibile su una base ininterrotta, tempestiva, sicura o
          priva di errori, o sar&agrave; accurato, affidabile, privo di virus o
          altri codici dannosi, completo, legale o sicuro. Se la legge
          applicabile richiede qualsiasi garanzia rispetto al sito, tutte queste
          garanzie sono limitate nella durata a novanta (90) giorni dalla data
          del primo utilizzo.
        </p>

        <p>
          Alcune giurisdizioni non consentono l&apos;esclusione di garanzie
          implicite, quindi l&apos;esclusione sopra potrebbe non applicarsi a
          voi. Alcune giurisdizioni non consentono limitazioni sulla durata di
          una garanzia implicita, quindi la limitazione sopra potrebbe non
          applicarsi a voi.
        </p>

        <p>
          <strong>Limitazione della Responsabilit&agrave;</strong>
        </p>
        <p>
          Nella massima misura consentita dalla legge, in nessun caso Quotabus o
          i nostri fornitori saranno responsabili nei vostri confronti o di
          terzi per qualsiasi perdita di profitti, perdita di dati, costi di
          approvvigionamento di prodotti sostitutivi, o per qualsiasi danno
          indiretto, consequenziale, esemplare, incidentale, speciale o punitivo
          derivante da o relativo a questi termini o al vostro uso di, o
          incapacit&agrave; di usare il sito anche se la societ&agrave; &egrave;
          stata informata della possibilit&agrave; di tali danni. L&apos;accesso
          e l&apos;uso del sito sono a vostra discrezione e rischio, e sarete
          unicamente responsabili per qualsiasi danno al vostro dispositivo o
          sistema informatico, o perdita di dati risultante da ci&ograve;.
        </p>

        <p>
          Nella massima misura consentita dalla legge, nonostante qualsiasi
          disposizione contraria contenuta qui, la nostra responsabilit&agrave;
          nei vostri confronti per qualsiasi danno derivante da o relativo a
          questo accordo, sar&agrave; sempre limitata a un massimo di cento euro
          (&euro;100). L&apos;esistenza di pi&ugrave; di una richiesta non
          ingrandir&agrave; questo limite. Accettate che i nostri fornitori non
          avranno alcuna responsabilit&agrave; di alcun tipo derivante da o
          relativa a questo accordo.
        </p>
        <p>
          Alcune giurisdizioni non consentono la limitazione o l&apos;esclusione
          della responsabilit&agrave; per danni incidentali o consequenziali,
          quindi la limitazione o esclusione sopra potrebbe non applicarsi a
          voi.
        </p>

        <p>
          <strong>Termini e Risoluzione</strong>
        </p>
        <p>
          Fermo restando quanto previsto in questa Sezione, questi Termini
          rimarranno pienamente in vigore ed effetto mentre utilizzate il Sito.
          Possiamo sospendere o terminare i vostri diritti di utilizzo del Sito
          in qualsiasi momento per qualsiasi motivo a nostra esclusiva
          discrezione, incluso per qualsiasi utilizzo del Sito in violazione di
          questi Termini. Alla risoluzione dei vostri diritti sotto questi
          Termini, il vostro Account e il diritto di accesso e utilizzo del Sito
          termineranno immediatamente. Capite che qualsiasi risoluzione del
          vostro Account pu&ograve; comportare la cancellazione del vostro
          Contenuto Utente associato al vostro Account dai nostri database
          attivi. Quotabus non avr&agrave; alcuna responsabilit&agrave; nei
          vostri confronti per qualsiasi risoluzione dei vostri diritti sotto
          questi Termini. Anche dopo la risoluzione dei vostri diritti sotto
          questi Termini, le seguenti disposizioni di questi Termini rimarranno
          in vigore. L&apos;uso continuato del Servizio costituir&agrave;
          accettazione delle modifiche.
        </p>

        <p>
          <strong>Politica sui Diritti d&apos;Autore</strong>
        </p>
        <p>
          Quotabus rispetta la propriet&agrave; intellettuale altrui e chiede
          che gli utenti del nostro Sito facciano altrettanto. In relazione al
          nostro Sito, abbiamo adottato e implementato una politica che rispetta
          la legge sul diritto d&apos;autore che prevede la rimozione di
          materiali che violano il diritto d&apos;autore e la terminazione degli
          utenti del nostro sito online che sono recidivi nella violazione dei
          diritti di propriet&agrave; intellettuale, inclusi i diritti
          d&apos;autore. Se credete che uno dei nostri utenti, attraverso
          l&apos;uso del nostro Sito, stia violando illecitamente il diritto
          d&apos;autore in un&apos;opera e desiderate che il materiale
          presumibilmente violato venga rimosso, le seguenti informazioni sotto
          forma di notifica scritta devono essere fornite a Quotabus
          all&rsquo;indirizzo email{' '}
          <a href="mailto:service@quotabus.com">
            <span style={{ color: '#1155cc' }}>
              <u>service@quotabus.com</u>
            </span>
          </a>
          :
        </p>
        <ul>
          <li>
            <p>La vostra firma fisica o elettronica;</p>
          </li>
          <li>
            <p>
              Identificazione dell&apos;opera o delle opere coperte da diritto
              d&apos;autore che si sostiene siano state violate;
            </p>
          </li>
          <li>
            <p>
              Identificazione del materiale sui nostri servizi che si sostiene
              sia violatorio e che si richiede di rimuovere;
            </p>
          </li>
          <li>
            <p>
              Informazioni sufficienti per consentirci di localizzare tale
              materiale;
            </p>
          </li>
          <li>
            <p>Il vostro indirizzo, numero di telefono e indirizzo e-mail;</p>
          </li>
          <li>
            <p>
              Una dichiarazione che avete la convinzione in buona fede che
              l&apos;uso del materiale oggetto di contestazione non sia
              autorizzato dal proprietario del diritto d&apos;autore, dal suo
              agente o dalla legge; e
            </p>
          </li>
          <li>
            <p>
              Una dichiarazione che le informazioni nella notifica sono accurate
              e, sotto pena di pergiurio, che siete i proprietari del diritto
              d&apos;autore che si sostiene sia stato violato o che siete
              autorizzati ad agire per conto del proprietario del diritto
              d&apos;autore.
            </p>
          </li>
        </ul>
        <p>
          Si prega di notare che qualsiasi rappresentazione errata di fatto
          materiale in una notifica scritta sottopone automaticamente la parte
          reclamante alla responsabilit&agrave; per eventuali danni, costi e
          spese legali sostenute da noi in connessione con la notifica scritta e
          l&apos;accusa di violazione del diritto d&apos;autore.
        </p>
        <p>
          <strong>Generale</strong>
        </p>
        <p>
          Questi Termini sono soggetti a revisioni occasionali e, se apportiamo
          modifiche sostanziali, potremmo notificarvi inviandovi un&apos;e-mail
          all&apos;ultimo indirizzo e-mail che ci avete fornito e/o pubblicando
          in modo evidente l&apos;avviso delle modifiche sul nostro Sito. Siete
          responsabili di fornirci il vostro indirizzo e-mail pi&ugrave;
          aggiornato. Nel caso in cui l&apos;ultimo indirizzo e-mail che ci
          avete fornito non sia valido, l&apos;invio dell&apos;e-mail contenente
          tale avviso costituir&agrave; comunque un avviso efficace delle
          modifiche descritte nell&apos;avviso. Qualsiasi modifica a questi
          Termini sar&agrave; effettiva al pi&ugrave; presto trenta (30) giorni
          di calendario dopo il nostro invio di un avviso via e-mail a voi o
          trenta (30) giorni di calendario dopo la nostra pubblicazione
          dell&apos;avviso delle modifiche sul nostro Sito. Queste modifiche
          saranno immediatamente effettive per i nuovi utenti del nostro Sito.
          L&apos;uso continuato del nostro Sito dopo l&apos;avviso di tali
          modifiche indicher&agrave; il vostro riconoscimento di tali modifiche
          e l&apos;accordo ad essere vincolati dai termini e condizioni di tali
          modifiche.
        </p>

        <p>
          <strong>Governo e Interpretazione dei Termini</strong>
        </p>
        <p>
          Questi termini saranno regolati ed interpretati in conformit&agrave;
          alle leggi italiane, con esclusione delle norme sul conflitto di
          leggi.
        </p>

        <p>
          <strong>Trattamento dei Dati Personali</strong>
        </p>
        <p>
          Entrambe le Parti rispetteranno tutti i requisiti applicabili della
          Legislazione sulla Protezione dei Dati. Questa Sezione &egrave; in
          aggiunta a, e non alleggerisce, rimuove o sostituisce, gli obblighi o
          i diritti di una Parte ai sensi della Legislazione sulla Protezione
          dei Dati. In questa Sezione, Leggi Applicabili significa (per quanto
          tempo e nella misura in cui si applicano a noi) la legge
          dell&apos;Unione Europea, la legge di qualsiasi stato membro
          dell&apos;Unione Europea e/o le leggi applicabili sulla privacy dei
          dati in Europa.
        </p>
        <p>
          Le Parti riconoscono che ai fini della Legislazione sulla Protezione
          dei Dati, voi siete il Soggetto dei Dati e/o il Controllore e noi
          siamo il Controllore e possiamo anche essere un Processore. I Servizi
          e il<span style={{ color: '#ff0000' }}> </span>Modulo d&apos;Ordine
          stabiliscono l&apos;ambito, la natura e lo scopo del trattamento da
          parte nostra, la durata del trattamento e i tipi di Dati Personali e
          le categorie di Soggetto dei Dati.
        </p>
        <p>
          Senza pregiudizio per la generalit&agrave; di questa Sezione,
          garantirete di avere tutti i consensi e gli avvisi necessari e
          appropriati in atto per consentire il trasferimento legittimo dei Dati
          Personali a noi e/o la raccolta legittima dei Dati Personali per la
          durata e gli scopi di questo Accordo.
        </p>
        <p>
          Senza pregiudizio per la generalit&agrave; di questa Sezione, noi
          dovremo, in relazione a qualsiasi Dato Personale trattato in
          connessione con l&apos;adempimento da parte nostra dei nostri obblighi
          sotto questo Accordo: (a) trattare Dati Personali solo su vostre
          istruzioni scritte documentate o consenso a meno che non siamo tenuti
          dalle Leggi Applicabili a trattare diversamente tali Dati Personali.
          Dove ci affidiamo alle Leggi Applicabili come base per il trattamento
          dei Dati Personali, vi informeremo prontamente di ci&ograve; prima di
          eseguire il trattamento richiesto dalle Leggi Applicabili a meno che
          tali Leggi Applicabili ci proibiscano di informarvi; (b) assicurare
          che abbiamo in atto misure tecniche e organizzative adeguate, riviste
          e accessibili da voi, per proteggere contro il trattamento non
          autorizzato o illecito di Dati Personali e contro la perdita
          accidentale o la distruzione o danneggiamento di Dati Personali,
          appropriati al danno che potrebbe derivare dal trattamento non
          autorizzato o illecito o dalla perdita accidentale, distruzione o
          danneggiamento e la natura dei dati da proteggere, tenuto conto dello
          stato dello sviluppo tecnologico e del costo dell&apos;attuazione di
          qualsiasi misura (tali misure possono includere, ove appropriato, la
          pseudonimizzazione e la crittografia dei Dati Personali, garantendo la
          riservatezza, l&apos;integrit&agrave;, la disponibilit&agrave; e la
          resilienza dei nostri sistemi e servizi, garantendo che la
          disponibilit&agrave; di e l&apos;accesso ai Dati Personali possa
          essere ripristinato in modo tempestivo dopo un incidente, e valutando
          regolarmente e valutando l&apos;efficacia delle misure tecniche e
          organizzative adottate da essa); (c) assicurare che tutto il personale
          che ha accesso e/o tratta Dati Personali sia obbligato a mantenere i
          Dati Personali confidenziali; e (d) Per tutti i trasferimenti
          internazionali di qualsiasi Dato Personale:
        </p>
        <ol>
          <li>
            <p>Fornire adeguate garanzie in relazione al trasferimento;</p>
          </li>
          <li>
            <p>
              Assicurare che il Soggetto dei Dati abbia diritti applicabili e
              rimedi legali efficaci;
            </p>
          </li>
          <li>
            <p>
              Rispettare i nostri obblighi sotto la Legislazione sulla
              Protezione dei Dati fornendo un livello adeguato di protezione a
              qualsiasi Dato Personale che viene trasferito; e
            </p>
          </li>
          <li>
            <p>
              Rispettare le istruzioni ragionevoli che ci saranno comunicate in
              anticipo dal Soggetto dei Dati in merito al trattamento dei Dati
              Personali;
            </p>
          </li>
          <li>
            <p>
              Assistervi, a vostre spese, nel rispondere a qualsiasi richiesta
              del Soggetto dei Dati e nel garantire il rispetto dei nostri
              obblighi sotto la Legislazione sulla Protezione dei Dati in
              materia di sicurezza, notifiche di violazione, valutazioni
              d&apos;impatto e consultazioni con autorit&agrave; di controllo o
              regolatori;
            </p>
          </li>
          <li>
            <p>Rispondere a qualsiasi richiesta del Soggetto dei Dati;</p>
          </li>
          <li>
            <p>
              notificarvi senza indebito ritardo non appena veniamo a conoscenza
              di una violazione dei Dati Personali;
            </p>
          </li>
          <li>
            <p>
              Su vostra direzione scritta, cancellare o restituire i Dati
              Personali e le copie degli stessi a voi alla terminazione di
              questo Accordo a meno che non sia richiesto dalla Legge
              Applicabile di conservare i Dati Personali; e
            </p>
          </li>
          <li>
            <p>
              Mantenere registri e informazioni completi e accurati per
              dimostrare la nostra conformit&agrave; con questa clausola e
              informarvi immediatamente se, a nostro avviso,
              un&apos;informazione infrange la Legislazione sulla Protezione dei
              Dati.
            </p>
          </li>
        </ol>
        <p>
          Acconsentite a che noi nominiamo un processore di terze parti dei Dati
          Personali ai sensi di questo Accordo, se necessario. Confermiamo che
          abbiamo stipulato o (come nel caso) stipuleremo con il processore di
          terze parti un accordo scritto che incorpora termini sostanzialmente
          simili a quelli stabiliti in questa Sezione di Trattamento dei Dati
          Personali e che in ogni caso riflettono e continueranno a riflettere i
          requisiti della Legislazione sulla Protezione dei Dati. Tra voi e noi,
          rimarremo pienamente responsabili per tutti gli atti o le omissioni di
          qualsiasi processore di terze parti nominato da noi ai sensi di questa
          Sezione.
        </p>

        <p>
          <strong>Comunicazioni Elettroniche</strong>
        </p>
        <p>
          Le comunicazioni tra voi e Quotabus utilizzano mezzi elettronici, sia
          che utilizziate il Sito o ci inviate email, sia che Quotabus pubblichi
          avvisi sul Sito o comunichi con voi tramite email. Ai fini
          contrattuali, voi (a) acconsentite a ricevere comunicazioni da
          Quotabus in forma elettronica; e (b) accettate che tutti i termini e
          le condizioni, gli accordi, gli avvisi, le divulgazioni e altre
          comunicazioni che Quotabus vi fornisce elettronicamente soddisfino
          qualsiasi obbligo legale che tali comunicazioni soddisferebbero se
          fossero in forma scritta cartacea.
        </p>

        <p>
          <strong>Termini Completi</strong>. Questi Termini costituiscono
          l&apos;intero accordo tra voi e noi riguardo all&apos;uso del Sito. Il
          nostro mancato esercizio o l&apos;applicazione di qualsiasi diritto o
          disposizione di questi Termini non funger&agrave; da rinuncia a tale
          diritto o disposizione. I titoli delle sezioni in questi Termini sono
          solo per comodit&agrave; e non hanno alcun effetto legale o
          contrattuale. La parola &quot;incluso&quot; significa &quot;incluso
          senza limitazioni&quot;. Se una disposizione di questi Termini
          &egrave; ritenuta non valida o inapplicabile, le altre disposizioni di
          questi Termini rimarranno inalterate e la disposizione non valida o
          inapplicabile sar&agrave; ritenuta modificata in modo che sia valida e
          applicabile nella massima misura consentita dalla legge. Il vostro
          rapporto con Quotabus &egrave; quello di un contraente indipendente, e
          nessuna delle parti &egrave; un agente o partner dell&apos;altra.
          Questi Termini, e i vostri diritti ed obblighi qui contenuti, non
          possono essere assegnati, subappaltati, delegati o altrimenti
          trasferiti da voi senza il previo consenso scritto di Quotabus, e
          qualsiasi tentativo di assegnazione, subappalto, delega o
          trasferimento in violazione di quanto sopra sar&agrave; nullo e senza
          effetto. Quotabus pu&ograve; liberamente assegnare questi Termini. I
          termini e le condizioni stabiliti in questi Termini saranno vincolanti
          per gli assegnatari.
        </p>

        <p>
          <strong>La tua privacy</strong>. Per piacere, leggi la nostra{' '}
          <Link style={{ color: '#1155cc' }} href="/privacy-policy">
            <u>Policy sulla Privacy</u>
          </Link>
          .
        </p>
        <p>
          <strong>Informazioni sui Diritti d&apos;Autore/Marchi</strong>
        </p>
        <p>
          Copyright &copy;. Tutti i diritti riservati. Tutti i marchi, loghi e
          marchi di servizio visualizzati sul Sito sono di nostra
          propriet&agrave; o di propriet&agrave; di terze parti. Non &egrave;
          consentito utilizzare questi Marchi senza il nostro previo consenso
          scritto o il consenso di tali terze parti che possono essere
          proprietarie dei Marchi.
        </p>

        <p>
          <strong>Informazioni di Contatto</strong>
        </p>
        <p>Nome dell&quot;: Quotabus SRL</p>
        <p>
          Email:{' '}
          <a href="mailto:info@quotabus.com">
            <span style={{ color: '#1155cc' }}>
              <u>service@quotabus.com</u>
            </span>
          </a>
        </p>
        <div id="_rc_sig"></div>
      </Container>
    </>
  );
};

export default TermsConditionPage;
