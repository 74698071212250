import React from 'react';
// import { ThemeProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
// import base from './themes/base';
import theme from './styles/theme';
// import GlobalStyles from './themes/globalStyles';
import RouterContainer from './router';
import './App.css';
import './locales/i18n';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <GlobalStyles /> */}
        <ThemeProvider theme={theme}>
          <RouterContainer />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
