import React, { useEffect } from 'react';
import { t } from 'i18next';
import { Container, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  Title,
  MainBox,
  CheckMark,
  HeadingWithIcon,
} from './success-styled.js';
import BusPartnerHeaderComponent from '../../components/HeaderComponent/buspartner.js';
import { CheckmarkIcon } from '../../assets/icon/index.js';
import { resetSubmitOffer } from '../../redux/reqhistory/slice';
import BusPartnerFooterComponent from '../../components/FooterComponent/buspartner.js';

const OfferSuccess = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSubmitOffer());
  }, []);
  return (
    <>
      <BusPartnerHeaderComponent />
      <Container>
        <MainBox>
          <Title>
            <HeadingWithIcon>
              <CheckMark>
                <CheckmarkIcon />
              </CheckMark>
              <Typography variant="h1" component="h1">
                {t('OfferSuccess.Title')}
              </Typography>
            </HeadingWithIcon>
            <Typography variant="h1" component="p">
              {t('OfferSuccess.Info')}
            </Typography>
          </Title>
        </MainBox>
      </Container>
      <BusPartnerFooterComponent />
    </>
  );
};

export default OfferSuccess;
