import React from 'react';
import { t } from 'i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  BusIcon2,
  KMIcon,
  PerdayIcon,
  PerNightIcon,
  PerMealIcon,
  MoonIcon,
  CalendarIcon,
} from '../../assets/icon';
import { useFormikContext } from 'formik';
import { HeadPart, TableContent, InputInfo } from './styled';

const AdditionalInformationComponent = ({ isClassActive }) => {
  const { values, handleChange, handleBlur } = useFormikContext();
  return (
    <>
      <HeadPart>
        <Typography component="h2">{t('AdditionalInfo.Title')}</Typography>
      </HeadPart>
      <TableContent>
        <TableContainer>
          <Table
            sx={{ width: '100%', maxWidth: '850px' }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '90px' }}></TableCell>
                <TableCell sx={{ width: '200px' }}>
                  {t('AdditionalInfo.Typology')}
                </TableCell>
                <TableCell sx={{ width: '200px' }}>
                  {t('AdditionalInfo.BaseValue')}
                </TableCell>
                <TableCell sx={{ width: '200px' }}>
                  {t('AdditionalInfo.Description')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* km included daily rate */}
              <TableRow>
                <TableCell>
                  <BusIcon2 />
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info1.Typology')}</TableCell>
                <TableCell>
                  <InputInfo className={isClassActive ? '' : 'text-label'}>
                    {isClassActive ? (
                      <TextField
                        inputProps={{ min: 0 }}
                        type="number"
                        name="daily_km_included"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (
                            e.key === 'e' ||
                            e.key === 'E' ||
                            e.key === '-' ||
                            e.key === '+'
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={values?.daily_km_included}
                        sx={{ width: '88px' }}
                      />
                    ) : (
                      <Typography>{values?.daily_km_included}</Typography>
                    )}
                    <Typography component="span">
                      {t('AdditionalInfo.Info1.Value')}
                    </Typography>
                  </InputInfo>
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info1.Description')}</TableCell>
              </TableRow>

              {/* Extra km */}
              <TableRow>
                <TableCell>
                  <KMIcon />
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info2.Typology')}</TableCell>
                <TableCell>
                  <InputInfo className={isClassActive ? '' : 'text-label'}>
                    {isClassActive ? (
                      <TextField
                        inputProps={{ min: 0 }}
                        type="number"
                        name="extra_km_price"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (
                            e.key === 'e' ||
                            e.key === 'E' ||
                            e.key === '-' ||
                            e.key === '+'
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={values?.extra_km_price}
                        sx={{ width: '88px' }}
                      />
                    ) : (
                      <Typography>{values?.extra_km_price}</Typography>
                    )}
                    <Typography component="span">
                      {t('AdditionalInfo.Info2.Value')}
                    </Typography>
                  </InputInfo>
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info2.Description')}</TableCell>
              </TableRow>

              {/* Extra driver */}
              <TableRow>
                <TableCell>
                  <PerdayIcon />
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info3.Typology')}</TableCell>
                <TableCell>
                  <InputInfo className={isClassActive ? '' : 'text-label'}>
                    {isClassActive ? (
                      <TextField
                        inputProps={{ min: 0 }}
                        type="number"
                        name="second_driver_price"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (
                            e.key === 'e' ||
                            e.key === 'E' ||
                            e.key === '-' ||
                            e.key === '+'
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={values?.second_driver_price}
                        sx={{ width: '88px' }}
                      />
                    ) : (
                      <Typography>{values?.second_driver_price}</Typography>
                    )}
                    <Typography component="span">
                      {t('AdditionalInfo.Info3.Value')}
                    </Typography>
                  </InputInfo>
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info3.Description')}</TableCell>
              </TableRow>

              {/* Driver accommodation */}
              <TableRow>
                <TableCell>
                  <PerNightIcon />
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info4.Typology')}</TableCell>
                <TableCell>
                  <InputInfo className={isClassActive ? '' : 'text-label'}>
                    {isClassActive ? (
                      <TextField
                        inputProps={{ min: 0 }}
                        type="number"
                        name="overnight_stay_cost"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (
                            e.key === 'e' ||
                            e.key === 'E' ||
                            e.key === '-' ||
                            e.key === '+'
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={values?.overnight_stay_cost}
                        sx={{ width: '88px' }}
                      />
                    ) : (
                      <Typography>{values?.overnight_stay_cost}</Typography>
                    )}
                    <Typography component="span">
                      {t('AdditionalInfo.Info4.Value')}
                    </Typography>
                  </InputInfo>
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info4.Description')}</TableCell>
              </TableRow>

              {/* Driver Meals */}
              <TableRow>
                <TableCell>
                  <PerMealIcon />
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info5.Typology')}</TableCell>
                <TableCell>
                  <InputInfo className={isClassActive ? '' : 'text-label'}>
                    {isClassActive ? (
                      <TextField
                        inputProps={{ min: 0 }}
                        type="number"
                        name="meal_cost"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (
                            e.key === 'e' ||
                            e.key === 'E' ||
                            e.key === '-' ||
                            e.key === '+'
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={values?.meal_cost}
                        sx={{ width: '88px' }}
                      />
                    ) : (
                      <Typography>{values?.meal_cost}</Typography>
                    )}
                    <Typography component="span">
                      {t('AdditionalInfo.Info5.Value')}
                    </Typography>
                  </InputInfo>
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info5.Description')}</TableCell>
              </TableRow>

              {/* Night driving (11pm - 6am) */}
              <TableRow>
                <TableCell>
                  <MoonIcon />
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info6.Typology')}</TableCell>
                <TableCell>
                  <InputInfo className={isClassActive ? '' : 'text-label'}>
                    {isClassActive ? (
                      <TextField
                        inputProps={{ min: 0 }}
                        type="number"
                        name="night_drive_price"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (
                            e.key === 'e' ||
                            e.key === 'E' ||
                            e.key === '-' ||
                            e.key === '+'
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={values?.night_drive_price}
                        sx={{ width: '88px' }}
                      />
                    ) : (
                      <Typography>{values?.night_drive_price}</Typography>
                    )}
                    <Typography component="span">
                      {t('AdditionalInfo.Info6.Value')}
                    </Typography>
                  </InputInfo>
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info6.Description')}</TableCell>
              </TableRow>

              {/* Weekend (Friday - Sunday) */}
              <TableRow>
                <TableCell>
                  <CalendarIcon />
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info7.Typology')}</TableCell>
                <TableCell>
                  <InputInfo className={isClassActive ? '' : 'text-label'}>
                    {isClassActive ? (
                      <TextField
                        inputProps={{ min: 0 }}
                        type="number"
                        name="markup_weekends"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (
                            e.key === 'e' ||
                            e.key === 'E' ||
                            e.key === '-' ||
                            e.key === '+'
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={values?.markup_weekends}
                        sx={{ width: '88px' }}
                      />
                    ) : (
                      <Typography>{`+${values?.markup_weekends}%`}</Typography>
                    )}
                    <Typography component="span">
                      {t('AdditionalInfo.Info7.Value')}
                    </Typography>
                  </InputInfo>
                </TableCell>
                <TableCell>{t('AdditionalInfo.Info7.Description')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TableContent>
    </>
  );
};

export default AdditionalInformationComponent;
