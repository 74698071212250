import React, { useState } from 'react';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Box,
  Container,
  InputAdornment,
  Typography,
  Button,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { handleSignup } from '../../../redux/auth';

const SignUp = () => {
  const dispatch = useDispatch();

  const [hideShowValue, setValues] = useState({
    password: '',
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...hideShowValue,
      showPassword: !hideShowValue.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Formik and YUP validation
  const initialValues = {
    email: '',
    password: '',
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: Yup.object({
        password: Yup.string()
          .min(5, 'At least 5 characters required')
          .max(10, 'Must be 10 characters or less')
          .required('password Required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('email Required'),
      }),
      onSubmit: (values) => {
        dispatch(handleSignup({ body: values }));
      },
    });

  return (
    <>
      <Container component="main" maxWidth="sm">
        <Typography variant="h2" color="text.primary" align="center">
          SignUp
        </Typography>
        <Box sx={{ maxWidth: 380, m: '0 auto', p: 2 }}>
          <Formik>
            <Form>
              <TextField
                sx={{ width: '318px' }}
                error={touched.email && errors.email ? true : false}
                name="email"
                id="email"
                label="Email"
                type="email"
                value={values.email}
                helperText={touched.email && errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="standard"
                margin="dense"
                required
              />
              <TextField
                error={touched.password && errors.password ? true : false}
                name="password"
                id="password"
                label="Password"
                type={hideShowValue.showPassword ? 'text' : 'password'}
                helperText={touched.password && errors.password}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="false"
                variant="standard"
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disableRipple
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {hideShowValue.showPassword ? 'hide' : 'show'}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                required
              />
              <Button
                variant="contained"
                sx={{ mt: 1 }}
                onClick={handleSubmit}
                type="submit"
              >
                Signup
              </Button>
            </Form>
          </Formik>
        </Box>
      </Container>
    </>
  );
};

export default SignUp;
