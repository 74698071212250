import React, { useEffect } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Divider,
  FormGroup,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import BusPartnerHeaderComponent from '../../components/HeaderComponent/buspartner';
import { getOfferDetail } from '../../redux/reqhistory';
import {
  FlashContent,
  Heading,
  ItineraryTitle,
  StatisticsBlock,
  FormBox,
  PartnerFormWrapp,
  DetailsBox,
  FormInputGroup,
} from './styled';
import { getStopSequence } from '../../utils/helper';
import BusPartnerFooterComponent from '../../components/FooterComponent/buspartner';

const SupplierOfferView = () => {
  const { tripCode, busPartnerCode, tripIntermediationOfferId } = useParams();
  const { offerDetail } = useSelector((state) => state.reqhistory);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEmpty(tripCode)) {
      dispatch(
        getOfferDetail({
          trip_request_code: tripCode,
          unique_code: busPartnerCode,
          trip_intermediation_offer_id: tripIntermediationOfferId,
        }),
      );
    }
  }, [tripCode]);

  return (
    <>
      <BusPartnerHeaderComponent />
      <Container maxWidth="md">
        <Heading></Heading>
        <DetailsBox>
          <ItineraryTitle>
            {t('Offer.Itinerary')}:{' '}
            <span>
              {offerDetail?.customer_segments &&
                getStopSequence(offerDetail?.customer_segments)}
            </span>
          </ItineraryTitle>
          <Stack
            divider={
              <Divider
                orientation="vertical"
                flexItem
                className="customDivider"
              />
            }
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            alignItems="center"
            justifyContent="space-between"
          >
            <StatisticsBlock>
              <Typography variant="h5">{tripCode}</Typography>
              <Typography variant="span">{t('Offer.RequestCode')}</Typography>
            </StatisticsBlock>
            <StatisticsBlock>
              <Typography variant="h5">
                {offerDetail?.departure_date}
              </Typography>
              <Typography variant="span">{t('Offer.DepatureDate')}</Typography>
            </StatisticsBlock>
            <StatisticsBlock>
              <Typography variant="h5">{offerDetail?.arrival_date}</Typography>
              <Typography variant="span">{t('Offer.ArrivalDate')}</Typography>
            </StatisticsBlock>
            <StatisticsBlock>
              <Typography variant="h5">{offerDetail?.passengers}</Typography>
              <Typography variant="span">{t('Offer.Pax')}</Typography>
            </StatisticsBlock>
            <StatisticsBlock>
              <Typography variant="h5">
                {offerDetail?.tot_empty_km} KM
              </Typography>
              <Typography variant="span">{t('Offer.TotalKM')}</Typography>
            </StatisticsBlock>
          </Stack>
        </DetailsBox>
        <DetailsBox>
          <FlashContent>
            <Typography variant="h4">
              {t('Offer.Table')} <span>{t('Offer.CustTrip')}</span>
            </Typography>
            <TableContainer
              component={Paper}
              sx={{ maxWidth: '950px', boxShadow: 'none', borderRadius: 0 }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t('Offer.Stop')}</TableCell>
                    <TableCell align="center">{t('Offer.From')}</TableCell>
                    <TableCell align="center">{t('Offer.To')}</TableCell>
                    <TableCell align="center">
                      {t('Offer.DepatureDateTime')}
                    </TableCell>
                    <TableCell align="center">
                      {t('Offer.ArrivalDateTime')}*
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offerDetail?.customer_segments &&
                    offerDetail?.customer_segments.map((seg, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          <span>{seg.departure_stop.address}</span>
                        </TableCell>
                        <TableCell align="center">
                          <span>{seg.destination_stop.address}</span>
                        </TableCell>
                        <TableCell align="center">
                          <strong>
                            {seg.departure_stop.departure_datetime}
                          </strong>
                        </TableCell>
                        <TableCell align="center">
                          <strong>
                            {seg.destination_stop.arrival_datetime}
                          </strong>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </FlashContent>
        </DetailsBox>
        <DetailsBox>
          <FormBox>
            <PartnerFormWrapp>
              <Typography variant="h4">{t('Offer.Form')}</Typography>
              <FormGroup row className="formWrapp">
                <FormInputGroup style={{ width: '25%' }}>
                  <Typography>{t('Offer.Name')}</Typography>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    placeholder={t('Offer.Name')}
                    type="text"
                    name="supplier_name"
                    value={offerDetail?.supplier?.supplier_name}
                  />
                </FormInputGroup>
                <FormInputGroup style={{ width: '25%' }}>
                  <Typography>{t('Offer.Telephone')}</Typography>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    placeholder={t('Offer.Telephone')}
                    type="number"
                    name="supplier_telephone_number"
                    value={offerDetail?.supplier?.supplier_telephone_number}
                    onKeyDown={(e) => {
                      if (e.key === 'e' || e.key === 'E' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormInputGroup>
                <FormInputGroup style={{ width: '50%' }}>
                  <Typography>{t('Offer.Email')}</Typography>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    placeholder={t('Offer.Email')}
                    type="email"
                    name="supplier_email"
                    value={offerDetail?.supplier?.supplier_email}
                  />
                </FormInputGroup>
              </FormGroup>
              <FormGroup row className="formWrapp">
                <FormInputGroup>
                  <Typography>{t('Offer.Price')}</Typography>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    placeholder={t('Offer.Price')}
                    type="number"
                    name="offer_amount"
                    value={offerDetail?.supplier?.offer_amount}
                    onKeyDown={(e) => {
                      if (e.key === 'e' || e.key === 'E' || e.key === '+') {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormInputGroup>
              </FormGroup>
              <Typography variant="h4">{t('Offer.AdditionalNote')}</Typography>
              <FormGroup row>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  multiline
                  rows={3}
                  name="additional_info"
                  placeholder={t('Offer.AdditionalInfoPlaceholder')}
                  value={offerDetail?.supplier?.additional_info}
                />
              </FormGroup>
            </PartnerFormWrapp>
          </FormBox>
        </DetailsBox>
      </Container>
      <BusPartnerFooterComponent />
    </>
  );
};

export default SupplierOfferView;
