import React from 'react';
import { BlockTitle, PriceWrap, TripBox } from './styled';
import { isEmpty } from 'lodash';
import { Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { split } from 'lodash';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';

export const formatItalianNumber = (number) => {
  const formattedNumber = Math.floor(number).toLocaleString('it-IT');
  return formattedNumber;
};

export const getTime = (datetime) => {
  return split(datetime, ' ')[1];
};
export const getDate = (datetime) => {
  return dayjs(split(datetime, ' ')[0]).format('DD-MM-YYYY');
};

export const TripDetailSummary = ({ tripData }) => {
  const { t } = useTranslation();
  return (
    <>
      <BlockTitle>{t('TripDetail.Summary')}</BlockTitle>
      <TripBox>
        <Grid container>
          <Grid item xs={12} sm={6} md={8} className="tripCol">
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="center"
              alignItems="center">
              <PriceWrap>
                <Typography variant="h2">
                  {formatItalianNumber(tripData?.price?.price)} €*
                  <span>
                    {tripData?.price?.include_vat
                      ? t('TripDetail.VATINCL', { vatper: 10 })
                      : t('TripDetail.VATEXCL', { vatper: 10 })}
                  </span>
                </Typography>
                <Typography variant="h4">{t('TripDetail.Price')}</Typography>
              </PriceWrap>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="tripCol">
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="center"
              alignItems="center">
              <PriceWrap>
                <Typography variant="h2">
                  {tripData?.request?.trip_request_code}
                </Typography>
                <Typography variant="h4" className="pl-0">
                  {t('TripDetail.RequestCode')}
                </Typography>
              </PriceWrap>
            </Stack>
          </Grid>
        </Grid>
      </TripBox>
    </>
  );
};

export const TripItinerary = ({ tripData }) => {
  const { t } = useTranslation();

  return (
    <>
      <BlockTitle>{t('TripDetail.Itinerary')}</BlockTitle>
      <TripBox>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
          className="verticle_timeline_wrapper">
          {!isEmpty(tripData) &&
            tripData?.customer_segments.map((step, index) => (
              <TimelineItem key={index} className="verticle_timeline">
                <TimelineSeparator>
                  <TimelineDot sx={{ background: 'black' }}>
                    {index + 1}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {/* tripData?.customer_segments[index - 1]['destination_stop']['arrival_datetime'] */}
                  {index !== 0 && (
                    <Typography>
                      {t('TripDetail.PointArrival', {
                        time: getTime(
                          tripData?.customer_segments[index - 1][
                            'destination_stop'
                          ]['arrival_datetime'],
                        ),
                        date: getDate(
                          tripData?.customer_segments[index - 1][
                            'destination_stop'
                          ]['arrival_datetime'],
                        ),
                      })}
                    </Typography>
                  )}

                  <Typography component="h3" className="steplabel">
                    {step?.departure_stop?.address}
                  </Typography>

                  <Typography>
                    {index === 0
                      ? t('TripDetail.StartDepature', {
                          time: getTime(
                            step?.departure_stop?.departure_datetime,
                          ),
                          date: getDate(
                            step?.departure_stop?.departure_datetime,
                          ),
                        })
                      : t('TripDetail.PointDepature', {
                          time: getTime(
                            step?.departure_stop?.departure_datetime,
                          ),
                          date: getDate(
                            step?.departure_stop?.departure_datetime,
                          ),
                        })}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          {!isEmpty(tripData) && tripData?.customer_segments && (
            <TimelineItem
              key={tripData?.customer_segments?.length + 1}
              className="verticle_timeline">
              <TimelineSeparator>
                <TimelineDot sx={{ background: 'black' }}>
                  {tripData?.customer_segments?.length + 1}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>
                  {t('TripDetail.EndArrival', {
                    time: getTime(
                      tripData?.customer_segments[
                        tripData?.customer_segments?.length - 1
                      ]['destination_stop']['arrival_datetime'],
                    ),
                    date: getDate(
                      tripData?.customer_segments[
                        tripData?.customer_segments?.length - 1
                      ]['destination_stop']['arrival_datetime'],
                    ),
                  })}
                </Typography>
                <Typography component="h3" className="steplabel">
                  {
                    tripData?.customer_segments[
                      tripData?.customer_segments?.length - 1
                    ]['destination_stop']['address']
                  }
                </Typography>
              </TimelineContent>
            </TimelineItem>
          )}
        </Timeline>
      </TripBox>
    </>
  );
};

export default TripDetailSummary;
