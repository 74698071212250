import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const HeadPart = styled.div`
  margin: 59px 0 0 0;
  h2 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: ${theme.colors.black};
    line-height: normal;
    margin: 7px 0 12px 0;
  }
`;

export const TableContent = styled.div`
  border-radius: 21px;
  border: 1px solid ${theme.colors.grey};
  padding: 27px 36px 21px;
  margin-bottom: 52px;
  @media screen and (max-width: 767px) {
    padding: 27px 15px 21px;
  }
  table {
    thead {
      th {
        color: ${theme.colors.black};
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.4px;
        text-align: center;
        border: none;
        padding: 0 8px 12px;
      }
    }
    tbody {
      tr {
        td {
          border: none;
          padding: 8px 8px;
          text-align: center;
          font-size: 15px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.3px;
          color: ${theme.colors.black};
          svg {
            width: 70px;
          }
          input {
            padding: 10.5px 10px 7.5px;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.3px;
            color: ${theme.colors.black};
          }
          fieldset {
            border: 1px solid ${theme.colors.grey};
            border-radius: 6px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
  button.MuiButton-contained {
    border-radius: 5px;
    background: ${theme.colors.primary};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 12px 16px;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
    text-transform: none;
    margin-left: 60px;
    margin-top: 33px;
    &:before {
      content: '+';
      font-size: 50px;
      line-height: 30px;
      position: relative;
      top: 3px;
      margin-right: 15px;
    }
  }
`;

export const InputInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  &.text-label {
    p {
      min-width: 88px;
    }
  }
  span.MuiTypography-root {
    margin-left: 10px;
  }
`;

export const DeleteButton = styled.div`
  width: 24px;
  height: 24px;
  background: ${theme.colors.grey1};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
`;
