import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const Header = styled.div`
  background: ${theme.colors.bluebar};
  padding: 15px 20px;
  ${theme.breakPoint.tablet} {
    padding: 10px 20px;
  }
  svg {
    width: 180px;
    ${theme.breakPoint.tablet} {
      width: 140px;
    }
  }
`;

export const HeaderLogo = styled.div`
  svg {
    width: 164px;
    padding-left: 13px;

    path {
      fill: ${theme.colors.white};

      &:last-child {
        fill: ${theme.colors.lightGreen};
      }
    }
  }
`;
