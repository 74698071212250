import React from 'react';
import { t } from 'i18next';
import { useFormikContext } from 'formik';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import {
  HeadPart,
  GrayDot,
  PurpleDot,
  CheckboxList,
  SeasonalityContent,
  SeasonInfo,
} from './styled';

const SeasonalityComponent = ({ isClassActive }) => {
  const { values, handleChange, handleBlur } = useFormikContext();
  const months = [
    {
      key: 'january',
      label: t('Seasonality.January'),
    },
    {
      key: 'february',
      label: t('Seasonality.February'),
    },
    {
      key: 'march',
      label: t('Seasonality.March'),
    },
    {
      key: 'april',
      label: t('Seasonality.April'),
    },
    {
      key: 'may',
      label: t('Seasonality.May'),
    },
    {
      key: 'june',
      label: t('Seasonality.June'),
    },
    {
      key: 'july',
      label: t('Seasonality.July'),
    },
    {
      key: 'august',
      label: t('Seasonality.August'),
    },
    {
      key: 'september',
      label: t('Seasonality.September'),
    },
    {
      key: 'october',
      label: t('Seasonality.October'),
    },
    {
      key: 'november',
      label: t('Seasonality.November'),
    },
    {
      key: 'december',
      label: t('Seasonality.December'),
    },
  ];
  return (
    <>
      <HeadPart>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography component="h2">{t('Seasonality.Title')}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <List>
              <ListItem>
                <GrayDot></GrayDot>
                <Typography component="p">{t('Seasonality.Low')}</Typography>
              </ListItem>
              <ListItem>
                <PurpleDot></PurpleDot>
                <Typography component="span"></Typography>
                <Typography component="p">{t('Seasonality.High')}</Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </HeadPart>
      <SeasonalityContent>
        <CheckboxList>
          <FormGroup>
            {months.map((month) => {
              const isActive = values?.seasonality_months.includes(month.key);
              return (
                <FormControlLabel
                  name="seasonality_months"
                  value={month.key}
                  key={month.key}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={isActive}
                  control={<Checkbox disabled={!isClassActive} />}
                  label={month.label}
                />
              );
            })}
          </FormGroup>
        </CheckboxList>
        <SeasonInfo>
          <Typography component="h5">
            {t('Seasonality.IncreaseRateText')}
          </Typography>
          {isClassActive ? (
            <TextField
              inputProps={{ min: 0, max: 100 }}
              type="number"
              name="markup_high_season"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter Rate"
              onKeyDown={(e) => {
                if (
                  e.key === 'e' ||
                  e.key === 'E' ||
                  e.key === '-' ||
                  e.key === '+'
                ) {
                  e.preventDefault();
                }
              }}
              value={values?.markup_high_season}
              sx={{ width: '86px', margin: 'auto' }}
            />
          ) : (
            <Typography>{`+${values?.markup_high_season}%`}</Typography>
          )}
        </SeasonInfo>
      </SeasonalityContent>
    </>
  );
};

export default SeasonalityComponent;
