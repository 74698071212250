import { createSlice } from '@reduxjs/toolkit';
import { getConfig, updateConfig } from '../management';

const initialState = {
  loading: false,
  config: {},
  configError: '',
  updateConfigDetail: {},
  updateConfigError: '',
};

const managementSlice = createSlice({
  name: 'management',
  initialState,
  reducers: {
    reset: () => initialState,
    resetUpdateConfig: (state) => {
      state.updateConfigDetail = {};
      state.updateConfigError = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConfig.pending, (state) => {
      state.loading = true;
      state.config = {};
      state.configError = '';
    });
    builder.addCase(getConfig.fulfilled, (state, action) => {
      state.loading = false;
      state.config = action.payload?.response.data.data;
    });
    builder.addCase(getConfig.rejected, (state, err) => {
      state.loading = false;
      state.configError = err?.payload?.message;
    });

    builder.addCase(updateConfig.pending, (state) => {
      state.loading = true;
      state.updateConfigDetail = {};
      state.updateConfigError = '';
    });
    builder.addCase(updateConfig.fulfilled, (state, action) => {
      state.loading = false;
      state.updateConfigDetail = action.payload?.response.data;
    });
    builder.addCase(updateConfig.rejected, (state, err) => {
      state.loading = false;
      state.updateConfigError = err?.payload?.message;
    });
  },
});

export const { reset, resetUpdateConfig } = managementSlice.actions;

export default managementSlice.reducer;
