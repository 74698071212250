import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, useFormik } from 'formik';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import {
  Alert,
  Button,
  Container,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import HeaderComponent from '../../../components/HeaderComponent';
import { UserIcon } from '../../../assets/icon/index.js';
import { Title, UserForm, MainBox } from './styled.js';
import { forgotPassword } from '../../../redux/auth/index.js';
import { resetForgotPassword } from '../../../redux/auth/slice.js';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const [successOpen, setSuccessOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false);
  const { loading, forgotPasswordDetail, forgotPasswordError } = useSelector(
    (state) => state.auth,
  );
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setFailOpen(false);
  };
  useEffect(() => {
    if (!isEmpty(forgotPasswordDetail)) {
      setSuccessOpen(true);
    }
    if (!isEmpty(forgotPasswordError)) {
      setFailOpen(true);
    }
  }, [forgotPasswordDetail, forgotPasswordError]);

  useEffect(() => {
    return () => {
      dispatch(resetForgotPassword());
    };
  }, []);

  // Formik and YUP validation
  const initialValues = {
    email: '',
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: Yup.object({
        email: Yup.string()
          .required(t('General.EmailRequired'))
          .email(t('General.InvalidEmail')),
      }),
      onSubmit: (values, { resetForm }) => {
        dispatch(forgotPassword(values));
        resetForm();
      },
    });
  return (
    <>
      <HeaderComponent />
      <Container>
        <MainBox>
          <Title>
            <Typography variant="h1" component="h1">
              {t('ForgotPassword.Title')}
            </Typography>
            <Typography variant="h1" component="p">
              {t('ForgotPassword.PasswordInfo')}
            </Typography>
          </Title>
          <UserForm>
            <Formik>
              <Form>
                <TextField
                  error={touched.email && errors.email ? true : false}
                  name="email"
                  id="email"
                  type="email"
                  placeholder="info@quotabus.com"
                  value={values.email}
                  helperText={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  required
                  sx={{ width: '100%' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <UserIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  disabled={loading}
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                >
                  {t('ForgotPassword.ResetPasswordButton')}
                </Button>
              </Form>
            </Formik>
          </UserForm>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successOpen}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              {t('ForgotPassword.Success')}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={failOpen}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}
            >
              {!isEmpty(forgotPasswordError) &&
                forgotPasswordError?.email?.map((error, index) => {
                  return <Typography key={index}>{error}</Typography>;
                })}
              {!isEmpty(forgotPasswordError) &&
                forgotPasswordError?.error?.map((error, index) => {
                  return <Typography key={index}>{error}</Typography>;
                })}
            </Alert>
          </Snackbar>
        </MainBox>
      </Container>
    </>
  );
};

export default ForgotPasswordPage;
